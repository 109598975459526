<template>
  <MouseCursor />
  <Preloader />
  <router-view />
  <BackToTop />
</template>

<script>
import MouseCursor from "./components/MouseCurson.vue";
import Preloader from "./components/Preloader.vue";
import BackToTop from "./components/BackToTop.vue";
export default {
  components: { MouseCursor, Preloader, BackToTop },
  mounted() {
    function handlePreloader() {
      if ($(".preloader").length) {
        $(".preloader").delay(200).fadeOut(500);
      }
    }
    $(window).on("load", function () {
      handlePreloader();
    });
  },
};
</script>

<style></style>
