<template>
  <div
    class="cta-area cta-three bg-cover"
    data-background="/assets/img/cta_bg.png"
  >
    <div class="overlay"></div>
    <div class="cta-area-inner pt-50">
      <div class="container">
        <div class="offset-xl-2 col-xl-6 offset-lg-1 col-lg-6 col-md-8">
          <div class="section-title">
            <p>GET STARTED</p>
            <h2 class="text-white">
              Changing Your Lives, Changing Your Futures
            </h2>
          </div>
          <router-link :to="{ name: 'About' }" class="main-btn mt-20"
            >Discover More</router-link
          >
        </div>

        <div
          class="offset-xl-4 col-xl-7 offset-lg-4 col-lg-7 offset-md-4 col-md-7"
        >
          <p class="text-white">
            There are many variations of passages of Lorem Ipsum available, but
            the majority have suffered alteration in some form, by injected
            humour, or randomised words look even slightly believable. If you
            are going to use a passage
          </p>
        </div>

        <div class="pop-up-video">
          <a
            href="https://www.youtube.com/watch?v=yFwGpiCs3ss"
            class="video-play-btn mfp-iframe"
          >
            <i class="las la-play"></i> <span></span>
          </a>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "CTA2",
  mounted() {
    //Magnific Pop-up

    $(".video-play-btn").magnificPopup({
      type: "iframe",
    });
  },
};
</script>
