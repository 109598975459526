<template>
    
    <!-- Pre-Loader -->
    <div class="preloader"></div>
</template>

<script>
export default {
    name: 'Preloader'
}
</script>