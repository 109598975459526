<template>
  <div class="project-section project-two section-padding pb-60 white-bg">
    <div class="container">
      <template v-if="type">
        <div class="row">
          <div class="col-xl-7 col-lg-7">
            <div class="section-title">
              <p>OUR PROJECTS</p>
              <h2>
                Delivering our clients more project clarity, greater insight,
                and less chaos.
              </h2>
            </div>
          </div>
        </div>
      </template>
      <div class="row" id="cases">
        <template v-if="cases && cases.length > 0">
          <template
            v-for="(card, i) in type ? cases.slice(1, 4) : cases"
            :key="card.id"
          >
            <div
              v-if="type"
              :class="`col-xl-4 col-lg-4 col-md-6 col-12 wow animated ${
                i === 0 ? 'fadeInLeft' : i === 1 ? 'fadeInRight' : 'fadeInUp'
              }`"
              :data-wow-delay="`${index(i + 1) * 200}ms`"
            >
              <CaseCard2 :card="card" :type="type" :index="index(i + 1)" />
            </div>
            <div
              v-else
              :key="card.id"
              :class="`col-xl-4 col-lg-4 col-md-6 col-12 wow animated ${
                i > 2 ? 'fadeInRight' : 'fadeInUp'
              }`"
              :data-wow-delay="`${index(i + 1) * 200}ms`"
            >
              <CaseCard2 :card="card" :type="type" />
            </div>
          </template>
        </template>
      </div>
    </div>
    <template v-if="type">
      <div class="explore-btn">
        <router-link to="/cases">
          View All Projects <i class="las la-arrow-right"></i>
        </router-link>
      </div>
    </template>
  </div>
</template>

<script>
import axios from "axios";
import CaseCard2 from "./CaseCard2.vue";

export default {
  components: {
    CaseCard2,
  },
  props: ["type"],
  methods: {
    index(i) {
      let k = i;
      if (!this.type) {
        if (k > 3) {
          k = k - 3;
        }
      }
      return k;
    },
  },
  data() {
    return {
      cases: [],
    };
  },
  async beforeMount() {
    try {
      let fetchedData = await axios.get("/assets/data/cases.json");
      this.cases = fetchedData.data.cases;
    } catch (e) {
      console.log(e);
    }
  },
  mounted() {
    // data-backround

    $("[data-background").each(function () {
      $(this).css(
        "background-image",
        "url( " + $(this).attr("data-background") + "  )"
      );
    });
  },
};
</script>
