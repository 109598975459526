<template>
  <Navbar1 />
  <HeroArea1 />
  <Feature />
  <Service1 />
  <CTA />
  <AboutInHome />
  <CTA1 />
  <Testimonial />
  <CaseInHome />
  <FAQ1 :className="true" />
  <TeamInHome :className="true" />
  <BlogInHome1 />
  <ContactComponent />
  <Footer1 />
</template>
<script>
import Navbar1 from "@/components/Navbar1.vue";
import Footer1 from "@/components/Footer1.vue";
import Feature from "@/components/feature/Feature.vue";
import CTA from "@/components/CTA.vue";
import ContactComponent from "@/components/ContactComponent.vue";
import BlogInHome1 from "@/components/blog/BlogInHome1.vue";
import TeamInHome from "@/components/team/TeamInHome.vue";
import HeroArea1 from "@/components/heroArea/HeroArea1.vue";
import Service1 from "@/components/service/Service1.vue";
import AboutInHome from "@/components/about/AboutInHome.vue";
import CTA1 from "@/components/cta/CTA1.vue";
import Testimonial from "@/components/testimonial/Testimonial.vue";
import FAQ1 from "@/components/faq/FAQ1.vue";
import CaseInHome from "@/components/case/CaseInHome.vue";

/*eslint-disable */
export default {
  name: "Home_1",
  components: {
    Navbar1,
    Footer1,
    Feature,
    CTA,
    ContactComponent,
    BlogInHome1,
    TeamInHome,
    HeroArea1,
    Service1,
    AboutInHome,
    CTA1,
    Testimonial,
    FAQ1,
    CaseInHome,
  },
  mounted() {
    // data-backround

    $("[data-background").each(function () {
      $(this).css(
        "background-image",
        "url( " + $(this).attr("data-background") + "  )"
      );
    });

    //jQuery Animation
    new WOW().init();

    //Magnific Pop-up

    $(".video-play-btn").magnificPopup({
      type: "iframe",
    });
  },
};
</script>
