<template>
  <div class="about-section white-bg section-padding">
    <div class="container">
      <div class="row">
        <div
          class="col-xl-6 col-lg-6 col-12 wow fadeInLeft animated"
          data-wow-delay="100ms"
        >
          <div
            class="about-bg-one"
            data-background="/assets/img/about/about-1.jpg"
          >
            <div class="about-tag-title">
              <h4>We Have Than 25 Years of Experience</h4>
            </div>
          </div>
        </div>
        <div
          class="col-xl-6 col-lg-6 col-12 wow fadeInUp animated"
          data-wow-delay="200ms"
        >
          <div class="about-content-wrap">
            <div class="section-title">
              <h6>About Company</h6>
              <h2>Professional Approach and Quality Services</h2>
            </div>
            <p>
              There are many variations of passages of Lorem Ipsum available,
              but the majority suffered alteration in some form, by injected
              humour, or randomised which don't look even slightly believable.
            </p>
            <div class="about-feature-list">
              <ul>
                <li>
                  <i class="las la-check-circle"></i> Best Taxation Service
                </li>
                <li>
                  <i class="las la-check-circle"></i>Business Strategy & Growth
                </li>
                <li>
                  <i class="las la-check-circle"></i>Highly Dedicated Worker
                </li>
                <li>
                  <i class="las la-check-circle"></i>99.9% Customer Satisfaction
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      <div class="row">
        <div
          class="col-xl-6 col-lg-6 col-12 text-end wow fadeInUp animated"
          data-wow-delay="100ms"
        >
          <div class="about-content-wrap pr-60">
            <div class="section-title">
              <h6>Who We Are</h6>
              <h2>Providing Quality Tax Services & Consulting</h2>
            </div>
            <p>
              There are many variations of passages of Lorem Ipsum available,
              but the majority suffered alteration in some form, by injected
              humour, or randomised words which don't look even slightly
              believable
            </p>
            <div class="circle_progress_area">
              <div class="circle_progress_wrap">
                <div class="circle_progress_single">
                  <div class="circle_progress_box">
                    <CircleProgress
                      :percent="85"
                      :viewport="true"
                      :show-percent="false"
                      fillColor="#061815"
                    />
                    <span>85%</span>
                  </div>
                  <div class="circle_progress_content">
                    <h6>Financial Advising</h6>
                  </div>
                </div>
                <div class="circle_progress_single">
                  <div class="circle_progress_box">
                    <CircleProgress
                      :percent="95"
                      :viewport="true"
                      :show-percent="false"
                      fillColor="#061815"
                    />
                    <span>95%</span>
                  </div>
                  <div class="circle_progress_content">
                    <h6>Business Consulting</h6>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          class="col-xl-6 col-lg-6 col-12 wow fadeInRight animated"
          data-wow-delay="200ms"
        >
          <div
            class="about-bg-two"
            data-background="/assets/img/about/about-2.jpg"
          >
            <div class="about-tag-title">
              <h4>99.9% Customer Satisfaction</h4>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import CircleProgress from "vue3-circle-progress";
export default {
  name: "AboutInHome",
  components: { CircleProgress },
};
</script>
