<template lang="">
  <div class="cta-area bg-cover" data-background="/assets/img/cta_bg.jpg">
    <div class="overlay"></div>
    <div class="cta-area-inner pt-150">
      <div class="container">
        <div
          class="offset-xl-1 col-xl-7 offset-lg-1 col-lg-7 offset-md-1 col-md-7"
        >
          <div class="section-title">
            <h6>ABOUT OUR TAXVI</h6>
            <h2 class="text-white">
              Solve All Your Tax Problems Under One Room
            </h2>
          </div>
        </div>
        <div class="offset-xl-5 col-xl-6">
          <p class="text-white">
            There are many variations of passages of Lorem Ipsum available, but
            the majority have suffered alteration in some form, by injected
            humour, or randomised words look even slightly believable. If you
            are going to use a passage
          </p>
        </div>

        <div class="pop-up-video">
          <a
            href="https://www.youtube.com/watch?v=vNiRZWVNK7M"
            class="video-play-btn mfp-iframe"
          >
            <i class="las la-play"></i> <span></span>
          </a>
        </div>
        <div class="explore-btn">
          <router-link :to="{ name: 'ServiceDetails', params: { id: 1 } }"
            >Explore Our Service<i class="las la-arrow-right"></i
          ></router-link>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "CTA1",
  mounted() {
    //Magnific Pop-up

    $(".video-play-btn").magnificPopup({
      type: "iframe",
    });
  },
};
</script>
