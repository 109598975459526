<template>
  <div
    class="team-section section-padding light-bg-1"
    :class="{ 'pb-90': className }"
  >
    <div class="container">
      <div class="row justify-content-center">
        <div class="col-xl-8 text-center">
          <div class="section-title">
            <h6 v-if="className">Our Team Members</h6>
            <p v-else>Our Team Members</p>
            <h2>
              Our dedicated tax advisor financial consulting will help you!
            </h2>
          </div>
        </div>
      </div>
      <div class="row mt-30" v-if="team.length > 0">
        <div
          v-for="(member, idx) in className ? team.slice(0, 3) : team"
          :key="member.id"
          class="col-xl-4 col-lg-6 col-md-12 col-12 wow fadeInLeft animated"
          :data-wow-delay="`${index(idx + 1) * 200}ms`"
        >
          <TeamCard :team="member" />
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import axios from "axios";
import TeamCard from "./TeamCard.vue";
export default {
  name: "TeamInHome",
  props: ["className"],
  components: { TeamCard },
  data() {
    return {
      team: [],
    };
  },
  created() {
    this.getData();
  },
  methods: {
    async getData() {
      try {
        let fetchedData = await axios.get("/assets/data/team.json");
        this.team = fetchedData.data.team;
      } catch (error) {
        console.log(error);
      }
    },
    index(idx) {
      let k = idx + 1;
      if (k > 3) {
        k = k - 3;
      }
      return idx;
    },
  },
};
</script>
