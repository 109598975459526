<template>
  <div class="service-section service-two section-padding white-bg">
    <div class="container">
      <div v-if="type" class="row justify-content-center">
        <div class="col-xl-8 col-lg-8 text-center">
          <Heading />
        </div>
      </div>
      <div class="row">
        <AllService />
      </div>
      <div class="row">
        <div class="col-xl-12 text-center mt-60">
          <RouterLink class="main-btn" :to="{ name: 'Services' }"
            >View All Services</RouterLink
          >
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import Heading from "./Heading.vue";
import AllService from "./AllService.vue";
export default {
  name: "Service1",
  props: ["type"],
  components: { Heading, AllService },
};
</script>
