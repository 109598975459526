<template lang="">
  <div class="faq-section faq-two dark-bg section-padding">
    <div class="container">
      <div class="row">
        <div
          class="col-xl-6 col-lg-6 col-md-6 col-12 wow fadeInDown animated"
          data-wow-delay="200ms"
        >
          <div class="section-title">
            <p>General Questions</p>
            <h2 class="text-white">Frequently Asked Question.</h2>
          </div>
          <Accordion :type="true" />
        </div>
        <div
          class="col-xl-6 col-lg-6 col-md-6 col-12 wow fadeInUp animated"
          data-wow-delay="200ms"
        >
          <div class="contact-form-wrap">
            <div class="contact-form-header">
              <p>SEND A MESSAGE</p>
              <h2>Request A Call Back</h2>
            </div>

            <div class="contact-form-inner">
              <form action="#">
                <div class="row">
                  <div class="col-lg-6 col-12">
                    <input type="text" placeholder="Name" />
                  </div>
                  <div class="col-lg-6 col-12">
                    <input type="text" placeholder="Phone" />
                  </div>
                  <div class="col-lg-6 col-12">
                    <input type="email" placeholder="Email" />
                  </div>
                  <div class="col-lg-6 col-12">
                    <input type="text" placeholder="How can help you?" />
                  </div>
                  <div class="col-12">
                    <textarea
                      name="message"
                      id="message"
                      cols="30"
                      rows="10"
                      placeholder="Your message"
                    ></textarea>
                  </div>
                  <input type="submit" value="Send a Message" />
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import Accordion from "../Accordion.vue";
export default {
  name: "FAQ2",
  components: { Accordion },
};
</script>
