<template>
  <Navbar1 />
  <!-- Breadcrumb Area  -->
  <div class="breadcrumb-area services section-padding light-bg-1 pb-0">
    <div class="container">
      <div class="row justify-content-center">
        <div class="col-xl-6 col-lg-6 col-md-6 col-12 text-center">
          <div class="section-title">
            <p>Case studies</p>
            <h2>Let’s See Our Latest Project</h2>
          </div>
        </div>
      </div>

      <div class="row mt-90">
        <div class="col-12">
          <div class="bread-bg">
            <img src="/assets/img/breadcrumb/service_banner.jpg" alt="" />
          </div>
        </div>
      </div>
    </div>
  </div>

  <CaseSection :type="false" />
  <OurWorks />
  <Collaboration :background_shape="false" />
  <ContactComponent />
  <Footer1 />
</template>

<script>
import Navbar1 from "@/components/Navbar1.vue";
import Footer1 from "@/components/Footer1.vue";
import Collaboration from "@/components/Collaboration.vue";
import ContactComponent from "@/components/ContactComponent.vue";
import OurWorks from "@/components/OurWorks.vue";
import CaseSection from "@/components/case/CaseSection.vue";
export default {
  name: "Cases",
  components: {
    Navbar1,
    Footer1,
    Collaboration,
    ContactComponent,
    OurWorks,
    CaseSection,
  },
  mounted() {
    // data-backround

    $("[data-background").each(function () {
      $(this).css(
        "background-image",
        "url( " + $(this).attr("data-background") + "  )"
      );
    });

    //jQuery Animation
    new WOW().init();

    //Magnific Pop-up

    $(".video-play-btn").magnificPopup({
      type: "iframe",
    });
  },
};
</script>
