<template>
  <div class="header-top-area header-top-two dark-bg">
    <div class="container">
      <div class="row">
        <div class="col-xl-3 col-lg-3">
          <div class="header-info">
            <i class="las la-phone"></i>
            <p><span>Hotline</span>: +(+62)546 158 361</p>
          </div>
        </div>
        <div class="col-xl-6 col-lg-5 text-center">
          <div class="header-top-link">
            <p>
              Get Your Free Tax Advisor Today and Save 10% on Your First Session
              <router-link :to="{ name: 'Contact' }">Get Started</router-link>
            </p>
          </div>
        </div>

        <div class="col-xl-3 col-lg-4 text-end">
          <div class="header-top-right d-flex">
            <div class="login-link">
              <a href="#">Login</a>
            </div>
            <div class="search-bar">
              <input type="text" placeholder="Search Here" />
              <div class="search-icon">
                <i class="fal fa-search"></i>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <!-- Header Area -->

  <div class="header-area header-two">
    <div class="sticky-area">
      <div class="navigation">
        <div class="container">
          <div class="header-inner-box">
            <div class="logo">
              <router-link :to="{ name: 'Home2' }" class="navbar-brand"
                ><img src="/assets/img/logo.png" alt=""
              /></router-link>
            </div>

            <div class="main-menu">
              <nav class="navbar navbar-expand-lg">
                <button
                  class="navbar-toggler"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#navbarSupportedContent"
                  aria-controls="navbarSupportedContent"
                  aria-expanded="false"
                  aria-label="Toggle navigation"
                >
                  <span class="navbar-toggler-icon"></span>
                  <span class="navbar-toggler-icon"></span>
                  <span class="navbar-toggler-icon"></span>
                </button>

                <div
                  class="collapse navbar-collapse justify-content-center"
                  id="navbarSupportedContent"
                >
                  <ul class="navbar-nav m-auto">
                    <li class="nav-item">
                      <a class="nav-link active" href="#"
                        >Home
                        <span class="sub-nav-toggler"> </span>
                      </a>
                      <ul class="sub-menu">
                        <li>
                          <router-link :to="{ name: 'Home1' }"
                            >Home-1</router-link
                          >
                        </li>
                        <li>
                          <router-link :to="{ name: 'Home2' }"
                            >Home-2</router-link
                          >
                        </li>
                      </ul>
                    </li>
                    <li class="nav-item">
                      <a class="nav-link" href="#"
                        >Pages
                        <span class="sub-nav-toggler"> </span>
                      </a>
                      <ul class="sub-menu">
                        <li>
                          <router-link :to="{ name: 'About' }"
                            >About Us</router-link
                          >
                        </li>
                        <li>
                          <router-link :to="{ name: 'Team' }">Team</router-link>
                        </li>
                        <li>
                          <router-link :to="{ name: 'FAQ' }"
                            >Helpful FAQ</router-link
                          >
                        </li>
                        <li>
                          <router-link :to="{ name: 'Pricing' }"
                            >Pricing</router-link
                          >
                        </li>
                      </ul>
                    </li>

                    <li class="nav-item">
                      <a class="nav-link" href="#"
                        >Services
                        <span class="sub-nav-toggler"> </span>
                      </a>
                      <ul class="sub-menu">
                        <li>
                          <router-link :to="{ name: 'Services' }"
                            >Service
                          </router-link>
                        </li>
                        <li>
                          <router-link
                            :to="{ name: 'ServiceDetails', params: { id: 1 } }"
                            >Service Details</router-link
                          >
                        </li>
                      </ul>
                    </li>
                    <li class="nav-item">
                      <a class="nav-link" href="#"
                        >Cases
                        <span class="sub-nav-toggler"> </span>
                      </a>
                      <ul class="sub-menu">
                        <li>
                          <router-link :to="{ name: 'Cases' }"
                            >Cases
                          </router-link>
                        </li>
                        <li>
                          <router-link
                            :to="{ name: 'CaseDetails', params: { id: 1 } }"
                            >Cases Details</router-link
                          >
                        </li>
                      </ul>
                    </li>
                    <li class="nav-item">
                      <a class="nav-link" href="#"
                        >Blogs
                        <span class="sub-nav-toggler"> </span>
                      </a>
                      <ul class="sub-menu">
                        <li>
                          <router-link :to="{ name: 'Blogs' }"
                            >Blogs
                          </router-link>
                        </li>
                        <li>
                          <router-link
                            :to="{ name: 'BlogDetails', params: { id: 1 } }"
                            >Blog Details</router-link
                          >
                        </li>
                      </ul>
                    </li>

                    <li class="nav-item">
                      <router-link class="nav-link" :to="{ name: 'Contact' }"
                        >Contact Us</router-link
                      >
                    </li>
                  </ul>
                </div>
              </nav>
            </div>

            <div class="header-contact">
              <div class="header-contact-icon">
                <i class="las la-comments"></i>
              </div>
              <div class="header-contact-info">
                <p>Let's Chat</p>
                <h6>(208) 555-0112 89</h6>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Navbar2",
  mounted() {
    //Header Search Form
    if ($(".search-trigger").length) {
      $(".search-trigger").on("click", function () {
        $("body").addClass("search-active");
      });
      $(".close-search, .search-back-drop").on("click", function () {
        $("body").removeClass("search-active");
      });
    }

    // Mobile Menu

    $(".navbar-toggler").on("click", function () {
      $(this).toggleClass("active");
    });

    $(".navbar-nav li a").on("click", function () {
      $(".sub-nav-toggler").removeClass("active");
    });

    var subMenu = $(".navbar-nav .sub-menu");

    if (subMenu.length) {
      subMenu
        .parent("li")
        .children("a")
        .append(function () {
          return '<button class="sub-nav-toggler"> <i class="las la-angle-down"></i> </button>';
        });

      var subMenuToggler = $(".navbar-nav .sub-nav-toggler");

      subMenuToggler.on("click", function () {
        $(this).parent().parent().children(".sub-menu").slideToggle();
        return false;
      });
    }

    //jQuery Sticky Area
    $(".sticky-area").sticky({
      topSpacing: 0,
    });

    // Nice select
    $("select").niceSelect();

    // Active & Remove Class

    $(".sub-menu ul li").on("click", function () {
      $(".sub-menu").removeAttribute("style");
      $(this).addClass("active");
    });

    $("a.nav-link").on("mouseover", function () {
      $("a.nav-link").removeClass("active");
      $(this).addClass("active");
    });
  },
};
</script>
