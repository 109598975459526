<template>
  <div
    class="about-section about-two section-padding white-bg"
    :class="{ 'pt-0': className }"
  >
    <div class="container">
      <div class="row">
        <div
          class="col-xl-6 col-lg-6 col-12 wow fadeInDown animated"
          data-wow-delay="200ms"
        >
          <div class="about-left-wrap">
            <div class="section-title">
              <p>About Company</p>
              <h2>We Are The Best Solution For Your Tax Services</h2>
            </div>
            <div class="fouder-wrap light-bg-2">
              <div class="fouder-thumb">
                <img src="/assets/img/about/founder-thumb.png" alt="" />
              </div>
              <div class="founder-content">
                <div class="founder-title">
                  <h5>Jhon Richard, <span>Founder</span></h5>
                </div>
                <p>
                  Duis aute irure dolor in reprehenderit in voluptate velit esse
                  cillum dolore in fugiat nulla pariatur.
                </p>
              </div>
            </div>
            <div
              class="about-left-img wow fadeInUp animated"
              data-wow-delay="400ms"
            >
              <img src="/assets/img/about/about-3.jpg" alt="" />
              <RouterLink :to="{ name: 'About' }">Discover More</RouterLink>
            </div>
          </div>
        </div>
        <div
          class="col-xl-6 col-lg-6 col-12 wow fadeInRight animated"
          data-wow-delay="200ms"
        >
          <div class="about-right-wrap">
            <div class="about-right-img">
              <img src="/assets/img/about/about-4.jpg" alt="" />
            </div>
            <p>
              There are many variations of passages of Lorem Ipsum available,
              but the majority suffered alteration in some form, by injected
              humour, or randomised which don't look even slightly believable.
            </p>
            <div class="about-feature-list">
              <div class="row">
                <div class="col-md-6 col-12">
                  <ul>
                    <li>
                      <i class="las la-check-circle"></i> Best Taxation Service
                    </li>
                    <li>
                      <i class="las la-check-circle"></i>Business Strategy &
                      Growth
                    </li>
                  </ul>
                </div>
                <div class="col-md-6 col-12">
                  <ul>
                    <li>
                      <i class="las la-check-circle"></i>Highly Dedicated Worker
                    </li>
                    <li>
                      <i class="las la-check-circle"></i>99.9% Customer
                      Satisfaction
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "AboutCompany",
  props: ["className"],
};
</script>
