<template>
  <div class="collaboration-section section-padding white-bg">
    <div class="container">
      <div class="row">
        <div class="offset-xl-1 col-xl-10 offset-lg-1 col-lg-10 text-center">
          <div class="section-title">
            <p>Let's Collaboration</p>
            <h2>
              This Could Be The Start Of Something Special Let's Work Together!
            </h2>
          </div>
          <router-link :to="{ name: 'Contact' }" class="main-btn"
            >Get In Touch</router-link
          >
        </div>
      </div>
      <div class="row">
        <div class="instragram-wrapper mt-60">
          <swiper
            :modules="modules"
            :speed="500"
            :space-between="20"
            :loop="true"
            :autoplay="{
              delay: 3000,
              disableOnInteraction: false,
            }"
            :breakpoints="{
              '0': {
                slidesPerView: 2,
              },
              '530': {
                slidesPerView: 3,
              },
              '930': {
                slidesPerView: 4,
              },
              '1100': {
                slidesPerView: 5,
              },
            }"
            class="instragram-inner"
            v-if="collabs.length > 0"
          >
            <SwiperSlide
              class="instagram-item"
              v-for="collab in collabs"
              :key="collab.id"
            >
              <a href="https://www.instagram.com/">
                <img :src="collab.image" alt="" />
              </a>
            </SwiperSlide>
          </swiper>
        </div>
      </div>
    </div>

    <div v-if="background_shape" class="instagram-shape">
      <img src="/assets/img/instagram_shape.png" alt="" />
    </div>
  </div>
</template>

<script>
import axios from "axios";
import { Swiper, SwiperSlide } from "swiper/vue";
import { Autoplay } from "swiper";
import "swiper/css";
import "swiper/css/autoplay";
export default {
  name: "Collaboration",
  props: ["background_shape"],
  components: { Swiper, SwiperSlide },
  setup() {
    return {
      modules: [Autoplay],
    };
  },
  data() {
    return {
      collabs: [],
    };
  },
  async beforeMount() {
    try {
      let fetchedData = await axios.get("/assets/data/collaboration.json");
      this.collabs = fetchedData.data.collabs;
    } catch (e) {
      console.log(e);
    }
  },
};
</script>
