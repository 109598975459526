<template>
  <div class="footer-area">
    <div class="container">
      <div class="row">
        <div
          class="col-xl-3 col-lg-3 col-md-5 col-12 wow fadeInUp animated"
          data-wow-delay="200ms"
        >
          <div class="footer-widget">
            <div class="logo">
              <router-link class="navbar-brand" :to="{ name: 'Home1' }"
                ><img src="/assets/img/logo.png" alt=""
              /></router-link>
            </div>
            <p class="company-desc">
              Nam libero tempore, cum soluta nobis est eligendi optio cumque
              nihil impedit minus id quod maxime placeat facere assumenda est
              omnis dolor repellendus.
            </p>
            <div class="support-info">
              <div class="support-icon">
                <i class="las la-phone-volume"></i>
              </div>
              <div class="support-content">
                <h6>Free Support</h6>
                <p>0524 6230 2528</p>
              </div>
            </div>
          </div>
        </div>
        <div
          class="offset-xl-1 col-xl-5 col-lg-5 col-md-6 col-12 wow fadeInUp animated"
          data-wow-delay="200ms"
        >
          <div class="footer-widget">
            <div class="row">
              <div class="col-md-6">
                <div class="services-list">
                  <h5>Our Services</h5>
                  <ul>
                    <li>
                      <i class="las la-check-circle"></i
                      ><router-link
                        :to="{ name: 'ServiceDetails', params: { id: 1 } }"
                        >Insurance Tax</router-link
                      >
                    </li>
                    <li>
                      <i class="las la-check-circle"></i
                      ><router-link
                        :to="{ name: 'ServiceDetails', params: { id: 1 } }"
                        >Audit & Assurancy</router-link
                      >
                    </li>
                    <li>
                      <i class="las la-check-circle"></i
                      ><router-link
                        :to="{ name: 'ServiceDetails', params: { id: 1 } }"
                        >Strategic Planning</router-link
                      >
                    </li>
                    <li>
                      <i class="las la-check-circle"></i
                      ><router-link
                        :to="{ name: 'ServiceDetails', params: { id: 1 } }"
                        >Financial Planning</router-link
                      >
                    </li>
                    <li>
                      <i class="las la-check-circle"></i
                      ><router-link
                        :to="{ name: 'ServiceDetails', params: { id: 1 } }"
                        >Finance Secure</router-link
                      >
                    </li>
                    <li>
                      <i class="las la-check-circle"></i
                      ><router-link
                        :to="{ name: 'ServiceDetails', params: { id: 1 } }"
                        >Porject Management</router-link
                      >
                    </li>
                  </ul>
                </div>
              </div>
              <div class="col-md-6">
                <div class="footer-contact-info">
                  <h5>Contact Us</h5>
                  <p class="address">75 Garuda., Maju, INA 789</p>
                  <p class="number">050 520 6320 25</p>
                  <p class="email">support@yourdomain.tld</p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          class="col-xl-3 col-lg-3 col-12 wow fadeInUp animated"
          data-wow-delay="600ms"
        >
          <div class="footer-widget">
            <div class="project-gallery">
              <h5>Recent Project</h5>
              <img src="/assets/img/footer/1.jpg" alt="" />
              <img src="/assets/img/footer/2.jpg" alt="" />
              <img src="/assets/img/footer/3.jpg" alt="" />
              <img src="/assets/img/footer/4.jpg" alt="" />
              <img src="/assets/img/footer/5.jpg" alt="" />
              <img src="/assets/img/footer/6.jpg" alt="" />
            </div>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-xl-12 text-center">
          <div
            class="founder-year-wrap wow fadeInUp animated"
            data-wow-delay="300ms"
          >
            <span>since 1990</span>
          </div>
          <div class="footer-navbar">
            <ul>
              <li>
                <router-link :to="{ name: 'About' }">About Us</router-link>
              </li>
              <li>
                <router-link :to="{ name: 'Services' }">Service </router-link>
              </li>
              <li><router-link :to="{ name: 'Team' }">Team</router-link></li>
              <li><a href="#">Testimonial</a></li>
              <li><router-link :to="{ name: 'Blogs' }">Blogs </router-link></li>
              <li>
                <router-link :to="{ name: 'Contact' }">Contact Us</router-link>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
    <div class="footer-shape">
      <img src="/assets/img/shape/shape-7.png" alt="" />
    </div>
  </div>

  <div class="footer-bottom yellow-bg">
    <p>©Copyright 2023. All Rights Reserved. DevGalaxy</p>
  </div>
</template>
<script>
export default {
  name: "Footer1",
};
</script>
