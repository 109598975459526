<template>
  <div class="hero-area light-bg-2">
    <div class="container">
      <div
        class="hero-area-content wow fadeInUp animated"
        data-wow-delay="200ms"
      >
        <h3>Your Trusted Tax Advisor.</h3>
        <h1>Trustworthy Tax <br />Advice</h1>
        <p>
          Duis aute irure dolor in reprehenderit in voluptate velit esse cillum
          dolore eu fugiat nulla pariatur. Excepteur sint sunt in culpa qui
          officia deserunt
          <router-link :to="{ name: 'About' }">Discover More</router-link>
        </p>
      </div>
      <Swiper
        :modules="modules"
        :slides-per-view="1"
        :speed="1500"
        :simulate-touch="false"
        :pagination="{ clickable: true }"
        class="homepage-slides"
        id="homepage-slides"
      >
        <SwiperSlide class="single-slide-item">
          <div
            class="slider-bg bg-cover"
            data-background="/assets/img/slider/slide-1.jpg"
          >
            <div class="overlay"></div>
          </div>

          <div class="play-btn">
            <a
              href="https://www.youtube.com/watch?v=vNiRZWVNK7M"
              class="video-play-btn mfp-iframe"
              >Play</a
            >
          </div>
        </SwiperSlide>

        <SwiperSlide class="single-slide-item">
          <div
            class="slider-bg bg-cover"
            data-background="/assets/img/slider/slide-2.jpg"
          >
            <div class="overlay"></div>
          </div>
          <div class="play-btn">
            <a
              href="https://www.youtube.com/watch?v=vNiRZWVNK7M"
              class="video-play-btn mfp-iframe"
              >Play</a
            >
          </div>
        </SwiperSlide>

        <SwiperSlide class="single-slide-item">
          <div
            class="slider-bg bg-cover"
            data-background="/assets/img/slider/slide-3.jpg"
          >
            <div class="overlay"></div>
          </div>
          <div class="play-btn">
            <a
              href="https://www.youtube.com/watch?v=vNiRZWVNK7M"
              class="video-play-btn mfp-iframe"
              >Play</a
            >
          </div>
        </SwiperSlide>
      </Swiper>
      <div class="client-area wow fadeInUp animated" data-wow-delay="200ms">
        <div class="client-area-inner">
          <div class="client-thumb">
            <img class="client-one" src="/assets/img/slider/1.jpg" alt="" />
            <img class="client-two" src="/assets/img/slider/2.jpg" alt="" />
            <img class="client-three" src="/assets/img/slider/3.jpg" alt="" />
          </div>
          <div class="client-area-title">
            <h6>Trusted Over 240+ Client Accorss Country</h6>
          </div>
        </div>
        <div class="help-btn">How can we help you?</div>
      </div>
    </div>
  </div>
</template>

<script>
import { Swiper, SwiperSlide } from "swiper/vue";
import { Pagination } from "swiper";

// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";
export default {
  name: "HeroArea1",
  components: { Swiper, SwiperSlide },
  data() {
    return {
      modules: [Pagination],
    };
  },
  mounted() {
    //Magnific Pop-up

    $(".video-play-btn").magnificPopup({
      type: "iframe",
    });
  },
};
</script>
