<template>
  <div class="process-section process-two section-padding white-bg">
    <div class="container">
      <div class="row">
        <div class="col-xl-6 col-lg-6 col-md-12 col-12">
          <div class="process-bg">
            <img src="/assets/img/process/process_bg-2.jpg" alt="" />
          </div>
        </div>
        <div class="col-xl-6 col-lg-6 col-md-12 col-12">
          <div class="process-content-wrap">
            <div class="section-title">
              <p>Our Process</p>
              <h2>Our Working Process</h2>
            </div>
            <p>
              There are many variations of passages of Lorem Ipsum available,
              but the majority suffered alteration in some form, by injected
              humour, or randomised which don't look even slightly believable.
            </p>
            <div
              class="single-process-item"
              v-for="process in processes"
              :key="process.id"
            >
              <div class="process-icon">
                <i :class="process.icon"></i>
              </div>
              <div class="process-content">
                <h4>{{ process.name }}</h4>
                <p>{{ process.description }}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
export default {
  name: "Process",
  data() {
    return {
      processes: [],
    };
  },
  async beforeMount() {
    let fetchedData = await axios.get("/assets/data/process.json");
    this.processes = fetchedData.data.processes;
  },
};
</script>
