<template>
  <div
    class="about-section about-two section-padding"
    :class="{ 'light-bg-2': className, 'white-bg': !className }"
  >
    <div class="container">
      <div class="row">
        <div
          class="col-xl-6 col-lg-6 col-12 wow fadeInLeft animated"
          data-wow-delay="200ms"
        >
          <div class="about-left-wrap">
            <img src="/assets/img/about/about-5.png" alt="" />
          </div>
        </div>
        <div
          class="col-xl-6 col-lg-6 col-12 wow fadeInUp animated"
          data-wow-delay="400ms"
        >
          <div class="about-right-wrap">
            <div class="section-title">
              <p>ABOUT Experience</p>
              <h2>Helping You Put Systems in Place that Work</h2>
            </div>
            <p>
              There are many variations of passages of Lorem Ipsum available,
              but the majority have suffered alteration in some form, by
              injected humour,
            </p>
            <div class="circle_progress_area pt-30">
              <div class="circle_progress_wrap">
                <div class="circle_progress_single">
                  <div class="circle_progress_box">
                    <CircleProgress
                      :percent="85"
                      :viewport="true"
                      :show-percent="false"
                      fillColor="#061815"
                    />
                    <span>85%</span>
                  </div>
                  <div class="circle_progress_content">
                    <h6>Financial Advising</h6>
                  </div>
                </div>
                <div class="circle_progress_single">
                  <div class="circle_progress_box">
                    <CircleProgress
                      :percent="95"
                      :viewport="true"
                      :show-percent="false"
                      fillColor="#061815"
                    />
                    <span>95%</span>
                  </div>
                  <div class="circle_progress_content">
                    <h6>Business Consulting</h6>
                  </div>
                </div>
                <div class="circle_progress_single">
                  <div class="circle_progress_box">
                    <CircleProgress
                      :percent="75"
                      :viewport="true"
                      :show-percent="false"
                      fillColor="#061815"
                    />
                    <span>75%</span>
                  </div>
                  <div class="circle_progress_content">
                    <h6>Brand Consulting</h6>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import CircleProgress from "vue3-circle-progress";
export default {
  name: "Experience",
  props: ["className"],
  components: { CircleProgress },
};
</script>
