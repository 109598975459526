<template>
  <div class="single-case-wrapper" :data-background="card.img">
    <div class="case-info-wrap">
      <h3>{{ card.name }}</h3>
      <p>{{ card.details_content_1.slice(0, 50) }}..</p>
      <h6>
        Location: <span>{{ card.location }}</span>
      </h6>
      <h6>
        Date: <span>{{ card.date }}</span>
      </h6>
      <router-link
        :to="{ name: 'CaseDetails', params: { id: card.id } }"
        class="main-btn"
      >
        Discover More
      </router-link>
    </div>
  </div>
</template>

<script>
export default {
  name: "CaseCard",
  props: ["card"],
  mounted() {
    // data-backround

    $("[data-background").each(function () {
      $(this).css(
        "background-image",
        "url( " + $(this).attr("data-background") + "  )"
      );
    });
  },
};
</script>
