<template>
  <Navbar1 />
  <!-- Breadcrumb Area  -->
  <div class="breadcrumb-area services section-padding light-bg-1">
    <div class="container">
      <div class="row justify-content-center">
        <div class="col-xl-8 col-lg-8 col-md-8 col-12 text-center">
          <div class="section-title">
            <p>PROJECT DETAILS</p>
            <h2>{{ currentCase.name }}</h2>
          </div>
        </div>
      </div>
      <div class="row justify-content-center">
        <div class="col-xl-10">
          <div class="project-details-info">
            <div class="project-details-inner mt-60">
              <div class="single-project-info">
                <h4>Clients</h4>
                <p>{{ currentCase.client }}</p>
              </div>
              <div class="single-project-info">
                <h4>Category</h4>
                <p>{{ currentCase.category }}</p>
              </div>
              <div class="single-project-info">
                <h4>Date</h4>
                <p>{{ currentCase.date }}</p>
              </div>
              <div class="single-project-info">
                <h4>Location</h4>
                <p>{{ currentCase.location }}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- Case Details Page  -->
  <div class="project-details-page pb-120 white-bg">
    <div class="container">
      <div class="row">
        <div class="col-12">
          <div class="bread-bg">
            <img :src="currentCase.banner_img" alt="" />
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-xl-12">
          <div class="project-details">
            <h2>Project Details</h2>
            <p>
              {{ currentCase.details_content_1 }}<br /><br />
              {{ currentCase.details_content_2 }}
            </p>
            <h2 class="mb-30">Service Provided</h2>
            <p>{{ currentCase.service_content_1 }}</p>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="pagination-wrap">
          <a href="#"><i class="las la-long-arrow-alt-left"></i>Previous</a>
          <a href="#"><i class="las la-long-arrow-alt-right"></i>Next</a>
        </div>
      </div>
    </div>
  </div>

  <OurWorks />
  <Collaboration :background_shape="false" />
  <ContactComponent />
  <Footer1 />
</template>

<script>
import axios from "axios";
import Navbar1 from "@/components/Navbar1.vue";
import Footer1 from "@/components/Footer1.vue";
import Collaboration from "@/components/Collaboration.vue";
import ContactComponent from "@/components/ContactComponent.vue";
import OurWorks from "@/components/OurWorks.vue";
export default {
  name: "CaseDetails",
  components: {
    Navbar1,
    Footer1,
    Collaboration,
    ContactComponent,
    OurWorks,
  },
  data() {
    return {
      currentCase: [],
    };
  },
  async beforeMount() {
    let fetchedData = await axios.get("/assets/data/cases.json");
    let cases = fetchedData.data.cases;

    this.currentCase = cases.find((item) => item.id == this.$route.params.id);
  },
  mounted() {
    // data-backround

    $("[data-background").each(function () {
      $(this).css(
        "background-image",
        "url( " + $(this).attr("data-background") + "  )"
      );
    });

    //jQuery Animation
    new WOW().init();

    //Magnific Pop-up

    $(".video-play-btn").magnificPopup({
      type: "iframe",
    });
  },
};
</script>
