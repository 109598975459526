<template>
        <!-- Mouse Cursor  -->
    <div class="mouseCursor cursor-outer"></div>
    <div class="mouseCursor cursor-inner"><span>Drag</span></div>
</template>
<script>
export default {
    name : 'MouseCursor',
    mounted(){
        		// Mouse Custom Cursor
		function itCursor() {
			var myCursor = jQuery(".mouseCursor");
			if (myCursor.length) {
				if ($("body")) {
					const e = document.querySelector(".cursor-inner"),
						t = document.querySelector(".cursor-outer");
					let n,
						i = 0,
						o = !1;
					(window.onmousemove = function(s) {
						o ||
							(t.style.transform =
								"translate(" + s.clientX + "px, " + s.clientY + "px)"),
							(e.style.transform =
								"translate(" + s.clientX + "px, " + s.clientY + "px)"),
							(n = s.clientY),
							(i = s.clientX);
					}),
					$("body").on("mouseenter", "button, a, .cursor-pointer", function() {
							e.classList.add("cursor-hover"), t.classList.add("cursor-hover");
						}),
						$("body").on("mouseleave", "button, a, .cursor-pointer", function() {
							($(this).is("a", "button") &&
								$(this).closest(".cursor-pointer").length) ||
							(e.classList.remove("cursor-hover"),
								t.classList.remove("cursor-hover"));
						}),
						(e.style.visibility = "visible"),
						(t.style.visibility = "visible");
				}
			}
		}
		itCursor();
    }
}
</script>