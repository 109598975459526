<template lang="">
  <div class="single-team-wrap">
    <div class="team-thumb">
      <img :src="team.img" alt="" />
    </div>
    <div class="team-details">
      <h4>{{ team.name }}</h4>
      <h6>{{ team.rank }}</h6>
      <p>{{ team.intro }}</p>
      <div class="social-link">
        <a href="#">FB</a>
        <a href="#">TW</a>
        <a href="#">LI</a>
        <a href="#">BE</a>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "TeamCard",
  props: ["team"],
};
</script>
