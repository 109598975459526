<template>
  <div class="single-blog-item">
    <div class="blog-meta">
      <span>{{ blog.tag }}</span> . <span>{{ blog.date }}</span>
    </div>
    <h3>
      <router-link :to="{ name: 'BlogDetails', params: { id: 1 } }">{{
        blog.title
      }}</router-link>
    </h3>
    <p>{{ blog.excerpt }}</p>
    <div class="blog-thumb">
      <img :src="blog.image" alt="" />
    </div>
  </div>
</template>
<script>
export default {
  name: "BlogCard",
  props: ["blog"],
};
</script>
