<template>
  <div class="hero-area hero-area-two">
    <Swiper
      :modules="modules"
      :slides-per-view="1"
      :speed="1500"
      :loop="true"
      :autoplay="{
        delay: 2500,
        disableOnInteraction: false,
      }"
      :pagination="{ clickable: true }"
      class="homepage-slides"
      id="homepage-slides"
    >
      <SwiperSlide class="single-slide-item">
        <div
          class="slider-bg bg-cover"
          data-background="/assets/img/slider/slide-4.jpg"
        >
          <div class="overlay-2"></div>
          <div class="container">
            <div class="hero-area-content">
              <h3>Make Your Taxes Easy</h3>
              <h1 class="text-white">Reliable & Trusted <br />Advice</h1>
              <p class="text-white">
                There are many variations of passages of Lorem available, but
                the majority have suffered alteration in some form, by injected
                humour, or randomised words
              </p>
              <router-link class="main-btn" :to="{ name: 'Contact' }"
                >Make an Appointment</router-link
              >
            </div>
            <div class="social-link">
              <a href="#">.FACEBOOK</a>
              <a href="#">.BEHANCE</a>
              <a href="#">.TELEGRAM</a>
              <a href="#">.DRIBBLE</a>
            </div>
          </div>
        </div>
        <div class="pop-up-video">
          <a
            href="https://www.youtube.com/watch?v=yFwGpiCs3ss"
            class="video-play-btn mfp-iframe"
          >
            <i class="las la-play"></i> <span></span>
          </a>
        </div>
      </SwiperSlide>
      <SwiperSlide class="single-slide-item">
        <div
          class="slider-bg bg-cover"
          data-background="/assets/img/slider/slide-2.jpg"
        >
          <div class="overlay-2"></div>
          <div class="container">
            <div class="hero-area-content">
              <h3>Make Your Taxes Easy</h3>
              <h1 class="text-white">Reliable & Trusted <br />Advice</h1>
              <p class="text-white">
                There are many variations of passages of Lorem available, but
                the majority have suffered alteration in some form, by injected
                humour, or randomised words
              </p>
              <router-link class="main-btn" :to="{ name: 'Contact' }"
                >Make an Appointment</router-link
              >
            </div>
            <div class="social-link">
              <a href="#">.FACEBOOK</a>
              <a href="#">.BEHANCE</a>
              <a href="#">.TELEGRAM</a>
              <a href="#">.DRIBBLE</a>
            </div>
          </div>
        </div>
        <div class="pop-up-video">
          <a
            href="https://www.youtube.com/watch?v=yFwGpiCs3ss"
            class="video-play-btn mfp-iframe"
          >
            <i class="las la-play"></i> <span></span>
          </a>
        </div>
      </SwiperSlide>
      <SwiperSlide class="single-slide-item">
        <div
          class="slider-bg bg-cover"
          data-background="/assets/img/slider/slide-4.jpg"
        >
          <div class="overlay-2"></div>
          <div class="container">
            <div class="hero-area-content">
              <h3>Make Your Taxes Easy</h3>
              <h1 class="text-white">Reliable & Trusted <br />Advice</h1>
              <p class="text-white">
                There are many variations of passages of Lorem available, but
                the majority have suffered alteration in some form, by injected
                humour, or randomised words
              </p>
              <router-link class="main-btn" :to="{ name: 'Contact' }"
                >Make an Appointment</router-link
              >
            </div>
            <div class="social-link">
              <a href="#">.FACEBOOK</a>
              <a href="#">.BEHANCE</a>
              <a href="#">.TELEGRAM</a>
              <a href="#">.DRIBBLE</a>
            </div>
          </div>
        </div>
        <div class="pop-up-video">
          <a
            href="https://www.youtube.com/watch?v=yFwGpiCs3ss"
            class="video-play-btn mfp-iframe"
          >
            <i class="las la-play"></i> <span></span>
          </a>
        </div>
      </SwiperSlide>
    </Swiper>
    <div class="founder-year">
      <h2>Since 1990</h2>
      <RouterLink :to="{ name: 'Contact' }">Contact With Us</RouterLink>
    </div>
  </div>
</template>
<script>
import { Swiper, SwiperSlide } from "swiper/vue";
import { Pagination, Autoplay } from "swiper";

// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";
export default {
  name: "HeroArea2",
  components: { Swiper, SwiperSlide },
  data() {
    return {
      modules: [Pagination, Autoplay],
    };
  },
  mounted() {},
};
</script>
