<template>
  <Navbar1 />

  <div class="breadcrumb-area services section-padding light-bg-1 pb-0">
    <div class="container">
      <div class="row justify-content-center">
        <div class="col-xl-10 col-lg-10 col-md-10 col-12 text-center">
          <div class="section-title">
            <p>Contact Us</p>
            <h2>Get our all info and also can message us directly from here</h2>
          </div>
        </div>
      </div>
    </div>
  </div>

  <!-- Contact Page  -->
  <div class="contact-page section-padding light-bg-1">
    <div class="container">
      <div class="contact-page-inner white-bg">
        <div class="row">
          <div class="col-xl-6 col-lg-6 col-md-6">
            <div class="contact-form-wrapper">
              <h3>Send A Message</h3>
              <form action="#">
                <input type="text" placeholder="Name" />
                <input type="tel" placeholder="Phone" />
                <input type="email" placeholder="E-mail" />
                <textarea
                  name="message"
                  id="message"
                  cols="30"
                  rows="10"
                  placeholder="Your Message"
                ></textarea>
                <input type="submit" value="Send A Message" />
              </form>
            </div>
          </div>
          <div class="col-xl-6 col-lg-6 col-md-6">
            <div class="contact-details-wrapper">
              <div class="section-title">
                <p>QUICK CONTACT</p>
                <h2>Have Questions? Get in Touch!</h2>
              </div>
              <p>
                Nemo enim ipsam voluptatem quia voluptas sit aspernatur aut odit
                fugit, sed quia consequuntur magni dolores eos qui ratione
              </p>
              <div class="contact-details">
                <div class="single-contact-info">
                  <div class="contact-icon">
                    <i class="las la-phone-volume"></i>
                  </div>
                  <div class="contact-info">
                    <p>Have any question?</p>
                    <h5>Free {{ contact.phone }}</h5>
                  </div>
                </div>
                <div class="single-contact-info">
                  <div class="contact-icon">
                    <i class="las la-envelope-open"></i>
                  </div>
                  <div class="contact-info">
                    <p>Write email</p>
                    <h5>{{ contact.mail }}</h5>
                  </div>
                </div>
                <div class="single-contact-info">
                  <div class="contact-icon">
                    <i class="las la-phone-volume"></i>
                  </div>
                  <div class="contact-info">
                    <p>Visit anytime</p>
                    <h5>{{ contact.address }}</h5>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <!-- Google Map Start-->
  <div class="contact-page google-map">
    <iframe
      src="https://www.google.com/maps/embed?pb=!1m16!1m12!1m3!1d3690.404245521138!2d91.80989606467384!3d22.338360085303748!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!2m1!1sdewanhat%20near%20Chattogram!5e0!3m2!1sen!2sbd!4v1677069314806!5m2!1sen!2sbd"
      width="600"
      height="600"
      style="border: 0"
      allowfullscreen=""
      loading="lazy"
      referrerpolicy="no-referrer-when-downgrade"
    ></iframe>
  </div>

  <Collaboration :background_shape="false" />
  <ContactComponent />
  <Footer1 />
</template>

<script>
import Collaboration from "@/components/Collaboration.vue";
import ContactComponent from "@/components/ContactComponent.vue";
import Navbar1 from "@/components/Navbar1.vue";
import Footer1 from "@/components/Footer1.vue";
import axios from "axios";
export default {
  name: "Contact",
  components: { Collaboration, Navbar1, Footer1, ContactComponent },
  data() {
    return {
      contact: [],
    };
  },
  async beforeMount() {
    let fetchedData = await axios.get("/assets/data/contact.json");
    this.contact = fetchedData.data.contact;
  },
  mounted() {
    // data-backround

    $("[data-background").each(function () {
      $(this).css(
        "background-image",
        "url( " + $(this).attr("data-background") + "  )"
      );
    });

    //jQuery Animation
    new WOW().init();

    // Contact Form JS

    $("#contactForm").on("submit", function (e) {
      e.preventDefault();

      var $action = $(this).prop("action");
      var $data = $(this).serialize();
      var $this = $(this);

      $this.prevAll(".alert").remove();

      $.post(
        $action,
        $data,
        function (data) {
          if (data.response == "error") {
            $this.before(
              '<div class="alert alert-danger">' + data.message + "</div>"
            );
          }

          if (data.response == "success") {
            $this.before(
              '<div class="alert alert-success">' + data.message + "</div>"
            );
            $this.find("input, textarea").val("");
          }
        },
        "json"
      );
    });
  },
};
</script>
