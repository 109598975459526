<template>
  <div
    class="faq-section section-padding"
    :class="{ 'dark-bg': className, 'white-bg': !className }"
  >
    <div class="container">
      <div className="row mb-60" v-if="!className">
        <div className="offset-xl-3 col-xl-6 offset-lg-3 col-lg-6 text-center">
          <div className="section-title">
            <h6>Frequently Asked Questions</h6>
            <h2>How Can We Help You?</h2>
          </div>
        </div>
      </div>
      <div class="row">
        <div
          class="col-xl-6 col-lg-6 col-12 wow fadeInUp animated"
          data-wow-delay="200ms"
        >
          <div class="section-title">
            <h6 v-if="className">Helpful Faq</h6>
            <p v-else>Helpful Faq</p>
            <h2 :class="{ 'text-white': className }">
              We Always Answer Your Doubts
            </h2>
          </div>
          <Accordion />
        </div>
        <div class="col-xl-6 col-lg-6 col-12">
          <div class="faq-section-gallery">
            <div
              class="faq-img-one wow fadeInDown animated"
              data-wow-delay="200ms"
            >
              <img src="/assets/img/faq_1.jpg" alt="" />
            </div>
            <div
              class="faq-img-two wow fadeInRight animated"
              data-wow-delay="300ms"
            >
              <img src="/assets/img/faq_3.jpg" alt="" />
            </div>
            <div
              class="faq-img-three wow fadeInUp animated"
              data-wow-delay="400ms"
            >
              <img src="/assets/img/faq_2.png" alt="" />
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="faq_shape_top" v-if="className">
      <img src="/assets/img/faq_shape_top.png" alt="" />
    </div>
    <div class="faq_shape_bottom" v-if="className">
      <img src="/assets/img/faq_shape_bottom.png" alt="" />
    </div>
  </div>
</template>
<script>
import Accordion from "../Accordion.vue";

export default {
  name: "FAQ1",
  props: ["className"],
  components: { Accordion },
};
</script>
