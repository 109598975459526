<template>
  <div class="counter-area bg-cover">
    <div class="overlay-2"></div>
    <div class="container">
      <div class="row">
        <div class="col-lg-3 col-md-6 col-sm-6">
          <div class="single-counter-box">
            <p class="counter-number" v-if="cta.project_completed">
              <CountUp
                :end-val="cta.project_completed"
                :autoplay="false"
                :duration="1"
                :options="options1"
              ></CountUp>
            </p>
            <h6>Project Completed</h6>
          </div>
        </div>
        <div class="col-lg-3 col-md-6 col-sm-6">
          <div class="single-counter-box">
            <p class="counter-number" v-if="cta.years_experience">
              <CountUp
                :end-val="cta.years_experience"
                :autoplay="false"
                :duration="1"
                :options="options2"
              ></CountUp>
            </p>
            <h6>Years Experience</h6>
          </div>
        </div>
        <div class="col-lg-3 col-md-6 col-sm-6">
          <div class="single-counter-box">
            <p class="counter-number" v-if="cta.happy_customers">
              <CountUp
                :end-val="cta.happy_customers"
                :autoplay="false"
                :duration="1"
                :options="options3"
              ></CountUp>
            </p>
            <h6>Happy Customers</h6>
          </div>
        </div>
        <div class="col-lg-3 col-md-6 col-sm-6">
          <div class="single-counter-box">
            <p class="counter-number" v-if="cta.professional_workers">
              <CountUp
                :end-val="cta.professional_workers"
                :autoplay="false"
                :duration="1"
                :options="options3"
              ></CountUp>
            </p>
            <h6>Professional Worker</h6>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import { ref } from "vue";
import CountUp from "vue-countup-v3";
export default {
  name: "CTA",
  components: {
    CountUp,
  },
  setup() {
    const options1 = ref({
      suffix: "k+",
      enableScrollSpy: true,
      scrollSpyDelay: 300,
      scrollSpyOnce: true,
    });
    const options2 = ref({
      suffix: "+",
      enableScrollSpy: true,
      scrollSpyDelay: 300,
      scrollSpyOnce: true,
    });
    const options3 = ref({
      enableScrollSpy: true,
      scrollSpyDelay: 300,
      scrollSpyOnce: true,
    });
    return {
      options1,
      options2,
      options3,
    };
  },
  data() {
    return {
      cta: {
        // project_completed: 15,
        // years_experience: 25,
        // happy_customers: 4073,
        // professional_workers: 402,
      },
    };
  },
  created() {
    this.getData();
  },
  methods: {
    async getData() {
      try {
        let fetchedData = await axios.get("/assets/data/cta.json");
        this.cta = fetchedData.data.cta;
      } catch (error) {
        console.log(error);
      }
    },
  },
};
</script>
