<template>
  <div class="feature-section feature-two section-padding white-bg">
    <div class="container">
      <div class="row">
        <div
          class="col-xl-4 col-lg-4 col-md-6 col-12 wow fadeInLeft animated"
          data-wow-delay="200ms"
        >
          <div class="single-feature-item">
            <div class="feature-item-inner">
              <div class="feature-icon">
                <i class="flaticon-money-2"></i>
              </div>
              <div class="feature-title">
                <h4 class="text-white">Best Taxation Service</h4>
              </div>
            </div>
            <p>
              All the Lorem Ipsum generators on the Internet tend to repeat
              predefined chunks
            </p>
            <RouterLink
              :to="{ name: 'ServiceDetails', params: { id: 1 } }"
              class="service-details-link"
            >
              <i class="las la-arrow-right"></i>
            </RouterLink>
          </div>
        </div>
        <div
          class="col-xl-4 col-lg-4 col-md-6 col-12 wow fadeInLeft animated"
          data-wow-delay="400ms"
        >
          <div class="single-feature-item">
            <div class="feature-item-inner">
              <div class="feature-icon">
                <i class="flaticon-bar-chart"></i>
              </div>
              <div class="feature-title">
                <h4 class="text-white">Business Strategy & Growth</h4>
              </div>
            </div>
            <p>
              All the Lorem Ipsum generators on the Internet tend to repeat
              predefined chunks
            </p>
            <router-link
              :to="{ name: 'ServiceDetails', params: { id: 1 } }"
              class="service-details-link"
            >
              <i class="las la-arrow-right"></i>
            </router-link>
          </div>
        </div>
        <div
          class="col-xl-4 col-lg-4 col-12 wow fadeInLeft animated"
          data-wow-delay="600ms"
        >
          <div class="single-feature-item">
            <div class="feature-item-inner">
              <div class="feature-icon">
                <i class="flaticon-hr"></i>
              </div>
              <div class="feature-title">
                <h4 class="text-white">Highly Dedicated Worker</h4>
              </div>
            </div>
            <p>
              All the Lorem Ipsum generators on the Internet tend to repeat
              predefined chunks
            </p>
            <router-link
              :to="{ name: 'ServiceDetails', params: { id: 1 } }"
              class="service-details-link"
            >
              <i class="las la-arrow-right"></i>
            </router-link>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "Feature2",
};
</script>
