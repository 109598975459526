<template>
  <Navbar1 />
  <!-- Breadcrumb Area  -->
  <div class="breadcrumb-area services section-padding light-bg-1 pb-0">
    <div class="container">
      <div class="row">
        <div
          class="offset-xl-1 col-xl-10 offset-lg-1 col-lg-10 offset-md-1 col-md-10 col-12 text-center"
        >
          <div class="section-title">
            <p>Our Services</p>
            <h2>We're Give A Full Service About All Your Kind Of Tax</h2>
          </div>
        </div>
      </div>

      <div class="row mt-90">
        <div class="col-12">
          <div class="bread-bg">
            <img src="/assets/img/breadcrumb/service_banner.jpg" alt="" />
          </div>
        </div>
      </div>
    </div>
  </div>

  <Service2 :type="false" />
  <ChooseUs />
  <OurWorks />
  <Process />
  <PricingSection />
  <CTA2 />
  <Collaboration :background_shape="true" />
  <Clients :type="false" />
  <ContactComponent />

  <Footer1 />
</template>

<script>
import Navbar1 from "@/components/Navbar1.vue";
import Footer1 from "@/components/Footer1.vue";
import Collaboration from "@/components/Collaboration.vue";
import ContactComponent from "@/components/ContactComponent.vue";
import Process from "@/components/Process.vue";
import PricingSection from "@/components/PricingSection.vue";
import Service2 from "@/components/service/Service2.vue";
import Clients from "@/components/Clients.vue";
import OurWorks from "@/components/OurWorks.vue";
import CTA2 from "@/components/cta/CTA2.vue";
import ChooseUs from "@/components/ChooseUs.vue";
export default {
  name: "Services",
  components: {
    Navbar1,
    Footer1,
    Collaboration,
    ContactComponent,
    Process,
    PricingSection,
    Service2,
    Clients,
    OurWorks,
    CTA2,
    ChooseUs,
  },

  mounted() {
    // data-backround

    $("[data-background").each(function () {
      $(this).css(
        "background-image",
        "url( " + $(this).attr("data-background") + "  )"
      );
    });

    //jQuery Animation
    new WOW().init();

    //Magnific Pop-up

    $(".video-play-btn").magnificPopup({
      type: "iframe",
    });
  },
};
</script>
