<template>
  <div class="section-title">
    <p>Our Services</p>
    <h2>We&apos;re Give A Full Service About All Your Kind Of Tax</h2>
  </div>
</template>
<script>
export default {
  name: "Heading",
};
</script>
