<template>
    <!-- Header Top Area -->

    <div class="header-top-area green-bg">
        <div class="container">
            <div class="row">
                <div class="col-xl-4 col-lg-5 col-md-5 col-12">
                    <div class="header-info">
                        <i class="las la-clock"></i>
                        <p><span>Working Hours</span>: Sunday-Monday, 09 a.m.- 5 p.m.</p>
                    </div>
                </div>
                <div class="col-xl-4 col-lg-3 col-md-3 col-12 text-center">
                    <div class="contact-info">                        
                        <a href="tel:+000(123)556989"><span>Call</span>: +000(123)556989</a>
                    </div>
                </div>

                <div class="col-xl-4 col-lg-4 col-md-4 col-12 text-end">
                    <div class="language-dropdown">                        
                        <select name="language">
                            <option value="default">US.English</option>
                            <option value="default">UK.English</option>
                            <option value="default">French</option>
                            <option value="default">Italian</option>
                        </select>
                    </div>
                    <div class="social-icon">                        
                        <a href="#"><i class="lab la-facebook-f"></i></a>
                        <a href="#"><i class="lab la-twitter"></i></a>
                        <a href="#"><i class="lab la-instagram"></i></a>
                        <a href="#"><i class="lab la-dribbble"></i></a>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <!-- Header Area -->

    <div class="header-area">
        <div class="sticky-area">
            <div class="navigation">
                <div class="container">
                    <div class="header-inner-box">     

                        <div class="logo">
                            <router-link class="navbar-brand" :to="{name: 'Home1'}"><img src="/assets/img/logo.png" alt=""></router-link>                            
                        </div>

                        <div class="main-menu">
                            <nav class="navbar navbar-expand-lg">
                                <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                                    <span class="navbar-toggler-icon"></span>
                                    <span class="navbar-toggler-icon"></span>
                                    <span class="navbar-toggler-icon"></span>
                                </button>

                                <div class="collapse navbar-collapse justify-content-center" id="navbarSupportedContent">
                                    <ul class="navbar-nav m-auto">
                                        <li class="nav-item">
                                            <a class="nav-link active" href="#">Home
                                                <span class="sub-nav-toggler"> </span>
                                            </a>
                                            <ul class="sub-menu">
                                                <li><router-link :to="{name: 'Home1'}">Home-1</router-link></li>
                                                <li><router-link :to="{name: 'Home2'}">Home-2</router-link></li>
                                            </ul>
                                        </li>
                                        <li class="nav-item">
                                            <a class="nav-link" href="#">Pages
                                                <span class="sub-nav-toggler"> </span>
                                            </a>
                                            <ul class="sub-menu">
                                                <li><router-link :to="{name: 'About'}">About Us</router-link></li>                                                
                                                <li><router-link :to="{name: 'Team'}">Team</router-link></li>
                                                <li><router-link :to="{name: 'FAQ'}">Helpful FAQ</router-link></li>
                                                <li><router-link :to="{name: 'Pricing'}">Pricing</router-link></li>
                                                
                                            </ul>
                                        </li> 
                                        
                                        <li class="nav-item">
                                            <a class="nav-link" href="#">Services
                                                <span class="sub-nav-toggler"> </span>
                                            </a>
                                            <ul class="sub-menu">
                                                <li>
                                                    <router-link :to="{name: 'Services'}">Service </router-link>
                                                </li>
                                                <li>
                                                    <router-link :to="{name: 'ServiceDetails', params:{id: 1} }">Service Details</router-link>
                                                </li>                                                
                                            </ul>
                                        </li>
                                        <li class="nav-item">
                                            <a class="nav-link" href="#">Cases
                                                <span class="sub-nav-toggler"> </span>
                                            </a>
                                            <ul class="sub-menu">
                                                <li>
                                                    <router-link :to="{name:'Cases'}">Cases </router-link>
                                                </li>
                                                <li>
                                                    <router-link :to="{name:'CaseDetails',params:{id: 1} }">Cases Details</router-link>
                                                </li>                                                
                                            </ul>
                                        </li>
                                        <li class="nav-item">
                                            <a class="nav-link" href="#">Blogs
                                                <span class="sub-nav-toggler"> </span>
                                            </a>
                                            <ul class="sub-menu">
                                                <li>
                                                    <router-link :to="{name: 'Blogs'}">Blogs </router-link>
                                                </li>
                                                <li>
                                                    <router-link :to="{name:'BlogDetails',params:{id: 1} }">Blog Details</router-link>
                                                </li>                                                
                                            </ul>
                                        </li>
                                                                                                          
                                        <li class="nav-item">
                                            <router-link class="nav-link" :to="{name: 'Contact'}">Contact Us</router-link>
                                        </li>
                                    </ul>
                                </div>
                            </nav>
                        </div>

                        <div class="header-right">
                            <div class="search-icon search-trigger"><i class="fal fa-search"></i></div>                                                        
                            <router-link :to="{name: 'Contact'}">Get in Touch <i class="fal fa-long-arrow-right"></i></router-link>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="header_right_bg">
            <img src="/assets/img/top_right_bg.png" alt="">
        </div>
    </div>
</template>
<script>
export default {
    name : "Navbar1",
    mounted() {
        //Header Search Form
        if ($(".search-trigger").length) {
            $(".search-trigger").on("click", function () {
                $("body").addClass("search-active");
            });
            $(".close-search, .search-back-drop").on("click", function () {
                $("body").removeClass("search-active");
            });
        }

        // Mobile Menu

        $(".navbar-toggler").on("click", function () {
            $(this).toggleClass("active");
        });

        $(".navbar-nav li a").on("click", function () {
            $(".sub-nav-toggler").removeClass("active");
        });

        var subMenu = $(".navbar-nav .sub-menu");

        if (subMenu.length) {
            subMenu
                .parent("li")
                .children("a")
                .append(function () {
                    return '<button class="sub-nav-toggler"> <i class="las la-angle-down"></i> </button>';
                });

            var subMenuToggler = $(".navbar-nav .sub-nav-toggler");

            subMenuToggler.on("click", function () {
                $(this).parent().parent().children(".sub-menu").slideToggle();
                return false;
            });
        }

        //jQuery Sticky Area
        $(".sticky-area").sticky({
            topSpacing: 0,
        });

        // Nice select
        $('select').niceSelect();        

        // Active & Remove Class 

        $(".sub-menu ul li").on("click", function () {
            $(".sub-menu").removeAttribute("style");
            $(this).addClass("active");
        });


        $("a.nav-link").on("mouseover", function () {
            $("a.nav-link").removeClass("active");
            $(this).addClass("active");
        });

      
    }

}
</script>