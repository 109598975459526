<template>
  <Navbar1 />
  <!-- Breadcrumb Area  -->
  <div class="breadcrumb-area services section-padding light-bg-1 pb-0">
    <div class="container">
      <div class="row justify-content-center">
        <div class="col-xl-6 col-lg-6 col-md-6 col-12 text-center">
          <div class="section-title">
            <p>{{ currentService.name }}</p>
            <h2>We Provide Quality Services.</h2>
          </div>
        </div>
      </div>

      <div class="row mt-90">
        <div class="col-12">
          <div class="bread-bg">
            <img :src="currentService.banner_image" alt="" />
            <div class="bg-tag">
              <h2>{{ currentService.name }}</h2>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- Service Page  -->
  <div class="service-page pb-60 white-bg">
    <div class="container">
      <div class="col-xl-12">
        <div class="service-page-content">
          <p>
            {{ currentService.content_1 }} <br />
            <br />
            {{ currentService.content_2 }}
          </p>
          <h2>{{ currentService.sub_heading }}</h2>
          <p>{{ currentService.sub_heading_content }}</p>

          <div
            class="video-section"
            :data-background="currentService.video_background_image"
          >
            <div class="pop-up-video">
              <a
                :href="currentService.video_link"
                class="video-play-btn mfp-iframe"
              >
                <i class="las la-play"></i> <span></span>
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <ChooseUs />
  <OurWorks />
  <Solutions />
  <Collaboration :background_shape="true" />
  <Clients :type="false" />
  <ContactComponent />
  <Footer1 />
</template>

<script>
import Navbar1 from "@/components/Navbar1.vue";
import Footer1 from "@/components/Footer1.vue";
import Collaboration from "@/components/Collaboration.vue";
import ContactComponent from "@/components/ContactComponent.vue";
import axios from "axios";
import ChooseUs from "@/components/ChooseUs.vue";
import OurWorks from "@/components/OurWorks.vue";
import Solutions from "@/components/Solutions.vue";
import Clients from "@/components/Clients.vue";
export default {
  name: "ServiceDetails",
  components: {
    Navbar1,
    Footer1,
    Collaboration,
    ContactComponent,
    ChooseUs,
    OurWorks,
    Solutions,
    Clients,
  },
  data() {
    return {
      currentService: {},
    };
  },
  async beforeMount() {
    let fetchedData = await axios.get("/assets/data/services.json");
    let services = fetchedData.data.services;

    this.currentService = services.find(
      (item) => item.id == this.$route.params.id
    );
  },
  mounted() {
    // data-backround
    setTimeout(() => {
      $("[data-background").each(function () {
        $(this).css(
          "background-image",
          "url( " + $(this).attr("data-background") + "  )"
        );
      });
    }, 1000);

    //jQuery Animation
    new WOW().init();

    //Magnific Pop-up

    $(".video-play-btn").magnificPopup({
      type: "iframe",
    });
  },
};
</script>
