<template>
    <!-- Pricing Section  -->
    <div class="pricing-section section-padding pb-90 light-bg-1">
        <div class="container">
            <div class="row">
                <div class="offset-xl-3 col-xl-6 text-center">
                    <div class="section-title">
                        <p>Our Pricing PLANS</p>
                        <h2>Simple, Transparent And 
                            Great pricing.</h2>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-xl-4 col-lg-4 col-md-6 col-12" v-for="price in prices" :key="price.id">
                    <div class="single-price-item">
                        <div class="price-heading">
                            <h4>{{price.name}}</h4>
                            <p>{{price.description}} </p>
                        </div>
                        <div class="price-amount">
                            <h2>{{price.amount}} <span>/ Month</span></h2>
                        </div>
                        <div class="price-feature-list">
                            <ul>
                                <li v-for="feature in price.features" :key="feature.id"><i class="las la-check-square"></i>{{feature.name}}</li>
                               
                                
                            </ul>
                            <div class="price-btn">
                                <a href="#" class="main-btn white">Purchase Now</a>
                            </div>
                        </div>
                        
                    </div>
                </div>


            </div>
        </div>
    </div>
</template>

<script>
import axios from 'axios'
export default {
    name : "PricingSection",
    data(){
		return {
			prices : []
		}
	},
	async beforeMount(){
		let fetchedData = await axios.get("/assets/data/prices.json");
    	this.prices = fetchedData.data.prices;
	},	
}
</script>