<template>
  <!-- Footer Area  -->

  <div class="footer-area footer-two">
    <div class="container">
      <div
        class="row justify-content-center pt-180 wow fadeInUp animated"
        data-wow-delay="200ms"
      >
        <div class="col-xl-10 text-center">
          <div class="footer-inner">
            <h2 class="text-white">
              Add Your Own Details and Inform All Your Tax Requirements
            </h2>
          </div>
        </div>
      </div>
      <div class="row mt-60 wow fadeInUp animated" data-wow-delay="400ms">
        <div class="offset-xl-2 col-xl-8">
          <div class="submit-form">
            <input type="email" placeholder="Your E-mail" />
            <input type="submit" value="Send Request" />
          </div>
        </div>
      </div>
      <div class="row mt-60 wow fadeInDown animated" data-wow-delay="600ms">
        <div class="col-md-6 col-12">
          <div class="contact-info d-flex">
            <h5 class="text-white"><span>Call Us:</span>024 5668 7675</h5>
            <h6 class="text-white">infocontact@email.com</h6>
          </div>
        </div>
        <div class="col-md-6 col-12 text-end">
          <div class="footer-menu">
            <ul>
              <li>
                <router-link :to="{ name: 'About' }"
                  >About Us <span>/</span></router-link
                >
              </li>
              <li>
                <router-link :to="{ name: 'Services' }"
                  >Services <span>/</span></router-link
                >
              </li>
              <li>
                <router-link :to="{ name: 'Cases' }"
                  >Projects <span>/</span></router-link
                >
              </li>
              <li>
                <router-link :to="{ name: 'Blogs' }"
                  >Blog<span>/</span></router-link
                >
              </li>
              <li>
                <router-link :to="{ name: 'Contact' }">Contact</router-link>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div class="row mt-60">
        <div class="footer-bottom">
          <p class="text-white">
            ©Copyright 2023. All Rights Reserved. DevGalaxy
          </p>
        </div>
      </div>
    </div>
  </div>

  <!-- back to top start -->
  <div class="progress-wrap">
    <svg
      class="progress-circle svg-content"
      width="100%"
      height="100%"
      viewBox="-1 -1 102 102"
    >
      <path d="M50,1 a49,49 0 0,1 0,98 a49,49 0 0,1 0,-98" />
    </svg>
  </div>
</template>

<script>
export default {
  name: "Footer2",
};
</script>
