import { createRouter, createWebHistory } from "vue-router";
import Home1 from "../views/Home1.vue";
import Contact from "../views/Contact.vue";
import About from "../views/AboutUs.vue";
import Team from "../views/Team.vue";
import FAQ from "../views/FAQ.vue";
import Pricing from "../views/Pricing.vue";
import Services from "../views/Services.vue";
import ServiceDetails from "../views/ServiceDetails.vue";
import Cases from "../views/Cases.vue";
import CaseDetails from "../views/CaseDetails.vue";
import Blogs from "../views/Blogs.vue";
import BlogDetails from "../views/BlogDetails.vue";
import Home2 from "../views/Home2.vue";

const routes = [
  {
    path: "/",
    name: "Home1",
    component: Home1,
  },
  {
    path: "/home2",
    name: "Home2",
    component: Home2,
  },
  {
    path: "/contact",
    name: "Contact",
    component: Contact,
  },
  {
    path: "/about-us",
    name: "About",
    component: About,
  },
  {
    path: "/team",
    name: "Team",
    component: Team,
  },
  {
    path: "/faq",
    name: "FAQ",
    component: FAQ,
  },
  {
    path: "/pricing",
    name: "Pricing",
    component: Pricing,
  },
  {
    path: "/services",
    name: "Services",
    component: Services,
  },
  {
    path: "/service-details/:id",
    name: "ServiceDetails",
    component: ServiceDetails,
  },

  {
    path: "/cases",
    name: "Cases",
    component: Cases,
  },

  {
    path: "/case-details/:id",
    name: "CaseDetails",
    component: CaseDetails,
  },

  {
    path: "/blogs",
    name: "Blogs",
    component: Blogs,
  },

  {
    path: "/blog-details/:id",
    name: "BlogDetails",
    component: BlogDetails,
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition;
    } else {
      return { top: 0 };
    }
  },
});

export default router;
