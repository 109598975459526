<template>
  <div
    class="contact-area section-padding"
    :class="className ? 'light-bg-1 bg-none' : 'pt-0'"
  >
    <div class="container">
      <div class="contact-section-inner">
        <div class="contact-section-content">
          <h2 class="text-white">Want to work with us?</h2>
          <h5 class="text-white">
            Meet our people. See our work. Join our team.
          </h5>
          <router-link :to="{ name: 'Contact' }" class="main-btn"
            >Contact With Us</router-link
          >
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "ContactComponent",
  props: ["className"],
};
</script>
