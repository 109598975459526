<template>
  <div class="solution-section section-padding pb-0 white-bg">
    <div class="row">
      <div class="offset-xl-2 col-xl-8 text-center">
        <div class="section-title">
          <h2>Solutions: All Your Questions Answered</h2>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="offset-xl-1 col-xl-10 mt-50">
        <div class="accordion faqs" id="accordionFaq2">
          <Accordion />
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import Accordion from "@/components/Accordion.vue";
export default {
  name: "Solutions",
  components: { Accordion },
};
</script>
