<template>
  <div class="blog-section section-padding pb-90 white-bg">
    <div class="container">
      <div class="row justify-content-center">
        <div class="col-xl-8 text-center">
          <div class="section-title">
            <h6>Blog & Articles</h6>
            <h2>
              Articles daily updated latest articles directly from the blog
            </h2>
          </div>
        </div>
      </div>
      <div class="row" v-if="blogs.length > 0">
        <div
          class="col-md-6 col-12 text-center wow fadeInLeft animated"
          v-for="(blog, i) in blogs.slice(0, 2)"
          :key="blog.id"
          :data-wow-delay="(i + 1) * 200 + 'ms'"
        >
          <BlogCard :blog="blog" />
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import axios from "axios";
import BlogCard from "./BlogCard.vue";
export default {
  name: "BlogInHome1",
  components: {
    BlogCard,
  },
  data() {
    return {
      blogs: [],
    };
  },
  created() {
    this.getData();
  },
  methods: {
    async getData() {
      try {
        let fetchedData = await axios.get("/assets/data/blogs.json");
        this.blogs = fetchedData.data.blogs;
      } catch (error) {
        console.log(error);
      }
    },
  },
};
</script>
