<template lang="">
  <div class="process-section section-padding white-bg">
    <div class="container mb-60">
      <div class="row">
        <div
          class="col-xl-6 col-lg-6 col-12 wow fadeInLeft animated"
          data-wow-delay="200ms"
        >
          <div
            class="process-bg"
            data-background="assets/img/process/process_bg.jpg"
          >
            <div class="process-tag-title">
              <h4>We Have Than 25 Years of Experience</h4>
            </div>
          </div>
        </div>
        <div
          class="col-xl-6 col-lg-6 col-12 wow fadeInUp animated"
          data-wow-delay="400ms"
        >
          <div class="process-content-wrap">
            <div class="section-title">
              <p>How We Works</p>
              <h2>Easy Steps To Get Our Tax Services</h2>
            </div>
            <p>
              There are many variations of passages of Lorem Ipsum available,
              but the majority suffered alteration in some form, by injected
              humour,
            </p>
          </div>

          <div class="single-process-wrap">
            <div class="single-process-inner">
              <h5>01/ Choose Your Services</h5>
              <p>
                There are many variations of passages of Lorem Ipsum <br />
                available, but the majority
              </p>
            </div>
            <div class="single-process-inner">
              <h5>02/ Make Appointment</h5>
              <p>
                There are many variations of passages of Lorem Ipsum <br />
                available, but the majority
              </p>
            </div>
            <div class="single-process-inner">
              <h5>03/ Make Payment</h5>
              <p>
                There are many variations of passages of Lorem Ipsum <br />
                available, but the majority
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "GetService",
};
</script>
