<template>
  <Navbar1 />
  <!-- Breadcrumb Area  -->
  <div class="breadcrumb-area services section-padding light-bg-1 pb-0">
    <div class="container">
      <div class="row">
        <div
          class="offset-xl-1 col-xl-10 offset-lg-1 col-lg-10 offset-md-1 col-md-10 col-12 text-center"
        >
          <div class="section-title">
            <p>Our Team</p>
            <h2>
              Trust the expertise of our tax advisor & financial consulting
            </h2>
          </div>
        </div>
      </div>

      <div class="row mt-90">
        <div class="col-12">
          <div class="bread-bg">
            <img src="/assets/img/breadcrumb/team_banner.jpg" alt="" />
          </div>
        </div>
      </div>
    </div>
  </div>

  <AboutCompany :className="false" />
  <TeamInHome :className="false" />
  <Experience />
  <OurWorks />
  <Collaboration :background_shape="false" />
  <ContactComponent />
  <Footer1 />
</template>

<script>
import Navbar1 from "@/components/Navbar1.vue";
import Footer1 from "@/components/Footer1.vue";
import Collaboration from "@/components/Collaboration.vue";
import ContactComponent from "@/components/ContactComponent.vue";
import OurWorks from "@/components/OurWorks.vue";
import AboutCompany from "@/components/about/AboutCompany.vue";
import TeamInHome from "@/components/team/TeamInHome.vue";
import Experience from "@/components/Experience.vue";
export default {
  name: "Team",
  components: {
    Navbar1,
    Footer1,
    Collaboration,
    ContactComponent,
    OurWorks,
    AboutCompany,
    TeamInHome,
    Experience,
  },
  mounted() {
    window.scrollTo(0, 0);
    // data-backround

    $("[data-background").each(function () {
      $(this).css(
        "background-image",
        "url( " + $(this).attr("data-background") + "  )"
      );
    });

    //jQuery Animation
    new WOW().init();

    //Magnific Pop-up

    $(".video-play-btn").magnificPopup({
      type: "iframe",
    });
  },
};
</script>
