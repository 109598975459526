<template>
  <Navbar1 />
  <!-- Breadcrumb Area  -->
  <div class="breadcrumb-area services section-padding light-bg-1 pb-0">
    <div class="container">
      <div class="row">
        <div
          class="offset-xl-3 col-xl-6 offset-lg-3 col-lg-6 offset-md-3 col-md-6 col-12 text-center"
        >
          <div class="section-title">
            <p>FAQ</p>
            <h2>Questions & Answers</h2>
          </div>
        </div>
      </div>

      <div class="row mt-90">
        <div class="col-12">
          <div class="bread-bg">
            <img src="/assets/img/breadcrumb/service_banner.jpg" alt="" />
          </div>
        </div>
      </div>
    </div>
  </div>

  <FAQ1 :className="false" />
  <OurWorks />
  <Solutions />
  <Collaboration :background_shape="false" />
  <ContactComponent />
  <Footer1 />
</template>
<script>
import Navbar1 from "@/components/Navbar1.vue";
import ContactComponent from "@/components/ContactComponent.vue";
import Footer1 from "@/components/Footer1.vue";
import Collaboration from "@/components/Collaboration.vue";
import axios from "axios";
import OurWorks from "@/components/OurWorks.vue";
import FAQ1 from "@/components/faq/FAQ1.vue";
import Solutions from "@/components/Solutions.vue";
export default {
  name: "FAQ",
  components: {
    Collaboration,
    ContactComponent,
    Navbar1,
    Footer1,
    OurWorks,
    FAQ1,
    Solutions,
  },
  data() {
    return {
      faqs: [],
    };
  },
  async beforeMount() {
    let fetchedData = await axios.get("/assets/data/faq.json");
    this.faqs = fetchedData.data.faq;
  },
  mounted() {
    // data-backround

    $("[data-background").each(function () {
      $(this).css(
        "background-image",
        "url( " + $(this).attr("data-background") + "  )"
      );
    });

    //jQuery Animation
    new WOW().init();

    //Magnific Pop-up

    $(".video-play-btn").magnificPopup({
      type: "iframe",
    });
  },
};
</script>
