<template>
  <div class="case-study section-padding white-bg">
    <div class="container">
      <div class="row">
        <div class="col-xl-7 col-lg-7">
          <div class="section-title">
            <h6>Case Studies</h6>
            <h2>
              Delivering our clients more project clarity, greater insight, and
              less chaos.
            </h2>
          </div>
        </div>
      </div>
      <div class="row" v-if="cases.length > 0">
        <div
          v-for="(sgCase, idx) in cases.slice(0, 2)"
          :key="sgCase.id"
          class="col-md-6 wow fadeInLeft animated"
          :class="{
            'col-xl-8 col-lg-7': idx === 0,
            'col-xl-4 col-lg-5': idx === 1,
          }"
          :data-wow-delay="`${(idx + 1.5) * 100}ms`"
        >
          <CaseCard :card="sgCase" />
        </div>
      </div>
    </div>
    <div class="explore-btn">
      <router-link :to="{ name: 'Cases' }"
        >View All Projects<i class="las la-arrow-right"></i
      ></router-link>
    </div>
  </div>
</template>
<script>
import axios from "axios";
import CaseCard from "./CaseCard.vue";
export default {
  name: "CaseInHome",
  data() {
    return {
      cases: [],
    };
  },
  async beforeMount() {
    try {
      let fetchedData = await axios.get("/assets/data/cases.json");
      this.cases = fetchedData.data.cases;
    } catch (e) {
      console.log(e);
    }
  },
  components: { CaseCard },
  mounted() {
    // data-backround

    $("[data-background").each(function () {
      $(this).css(
        "background-image",
        "url( " + $(this).attr("data-background") + "  )"
      );
    });
  },
};
</script>
