<template>
  <Navbar2 />
  <HeroArea2 />
  <Feature2 />
  <AboutCompany :className="true" />
  <OurWorks />
  <Service2 :type="true" />
  <Testimonial2 />
  <GetService />
  <FAQ2 />
  <CaseSection :type="true" />
  <Clients type="true" />
  <CTA2 />
  <Experience :className="true" />

  <!-- Contact Section  -->
  <div class="contact-section contact-two">
    <div class="container">
      <div
        class="contact-innner green-bg d-flex align-items-center justify-content-center"
      >
        <div class="text-left">
          <h3>New to tax advisor services</h3>
        </div>
        <div class="contact-icon">
          <i class="las la-phone"></i>
        </div>
        <div class="text-right">
          <h3>Talk to our expert +1 (234) 567 890</h3>
        </div>
      </div>
    </div>
  </div>
  <!-- Blog Section  -->
  <div class="blog-section section-padding white-bg pb-0">
    <div class="container">
      <div class="row justify-content-center">
        <div class="col-xl-8 text-center">
          <div class="section-title">
            <p>VISITING OUR BLOG</p>
            <h2>Latest News And Articles From The Blog Posts</h2>
          </div>
        </div>
      </div>
      <div class="row mt-30">
        <div
          class="col-lg-6 col-12 wow fadeInUp animated"
          data-wow-delay="200ms"
        >
          <div class="single-blog-item">
            <div class="blog-meta">
              <span>Taxation</span> . <span>25 March 2023</span>
            </div>
            <h3>
              <router-link :to="{ name: 'BlogDetails', params: { id: 1 } }"
                >If The White Whale Be Raised It Must Be In A Month</router-link
              >
            </h3>
            <p>
              Duis aute irure dolor in reprehenderit in voluptate velit esse
              dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat
            </p>
            <div class="blog-thumb">
              <img src="/assets/img/blog/3.jpg" alt="" />
            </div>
          </div>
        </div>
        <div
          class="col-lg-6 col-12 wow fadeInDown animated"
          data-wow-delay="200ms"
        >
          <div class="single-blog-item w-thumb">
            <div class="blog-meta">
              <span>Company</span> . <span>25 March 2023</span>
            </div>
            <h3>
              <router-link :to="{ name: 'BlogDetails', params: { id: 1 } }"
                >Job market cooling, workers still have power</router-link
              >
            </h3>
            <router-link
              :to="{ name: 'BlogDetails', params: { id: 1 } }"
              class="details-link"
              ><i class="las la-arrow-right"></i
            ></router-link>
          </div>
          <div class="single-blog-item w-thumb">
            <div class="blog-meta">
              <span>Accounting</span> . <span>25 March 2023</span>
            </div>
            <h3>
              <router-link :to="{ name: 'BlogDetails', params: { id: 1 } }"
                >The Compound Growth Rate Of Mutual Fund Schemes</router-link
              >
            </h3>
            <router-link
              :to="{ name: 'BlogDetails', params: { id: 1 } }"
              class="details-link"
              ><i class="las la-arrow-right"></i
            ></router-link>
          </div>
          <div class="single-blog-item w-thumb">
            <div class="blog-meta">
              <span>Taxation</span> . <span>25 March 2023</span>
            </div>
            <h3>
              <router-link :to="{ name: 'BlogDetails', params: { id: 1 } }"
                >Mastercards, just pushes deeper into crypto</router-link
              >
            </h3>
            <router-link
              :to="{ name: 'BlogDetails', params: { id: 1 } }"
              class="details-link"
              ><i class="las la-arrow-right"></i
            ></router-link>
          </div>
        </div>
      </div>
    </div>
  </div>

  <Collaboration :background_shape="true" />
  <!-- Consulting Social Area  -->
  <div class="consulting_social_area yellow-bg">
    <div class="container">
      <div class="consulting-social-link-wrap dark-green-bg">
        <div class="consulting-heading">
          <h6>Tax Advisor & Financial Consulting</h6>
        </div>
        <div class="social-link">
          <a href="#">Facebook</a>
          <a href="#">Twitter</a>
          <a href="#">Instagram</a>
          <a href="#">Linkedin</a>
          <a href="#">Youtube</a>
          <a href="#">WhatsApp</a>
        </div>
      </div>
    </div>
  </div>
  <Footer2 />
</template>

<script>
import Navbar2 from "@/components/Navbar2.vue";
import Footer2 from "@/components/Footer2.vue";
import FAQ2 from "@/components/faq/FAQ2.vue";
import Clients from "@/components/Clients.vue";
import OurWorks from "@/components/OurWorks.vue";
import HeroArea2 from "@/components/heroArea/HeroArea2.vue";
import Feature2 from "@/components/feature/Feature2.vue";
import AboutCompany from "@/components/about/AboutCompany.vue";
import Service2 from "@/components/service/Service2.vue";
import CTA2 from "@/components/cta/CTA2.vue";
import Collaboration from "@/components/Collaboration.vue";
import Experience from "@/components/Experience.vue";
import GetService from "@/components/GetService.vue";
import Testimonial2 from "@/components/testimonial/Testimonial2.vue";
import CaseSection from "@/components/case/CaseSection.vue";
export default {
  name: "Home2",
  components: {
    Navbar2,
    Footer2,
    FAQ2,
    Clients,
    OurWorks,
    HeroArea2,
    Feature2,
    AboutCompany,
    Service2,
    CTA2,
    Collaboration,
    Experience,
    Testimonial2,
    GetService,
    CaseSection,
  },
  mounted() {
    // data-backround

    $("[data-background").each(function () {
      $(this).css(
        "background-image",
        "url( " + $(this).attr("data-background") + "  )"
      );
    });

    //jQuery Animation
    new WOW().init();

    //Magnific Pop-up

    $(".video-play-btn").magnificPopup({
      type: "iframe",
    });
  },
};
</script>
