<template>
  <div :class="`single-project-item ${index === 3 ? 'last-child' : ''}`">
    <div
      :class="
        !type ? 'project-thumb projcet-item' : 'project-thumb project-img-card'
      "
    >
      <img :src="card.img" alt="" />
      <router-link
        :to="{ name: 'CaseDetails', params: { id: card.id } }"
        class="details-link"
        ><i class="las la-arrow-right"></i
      ></router-link>
    </div>
    <div class="project-content">
      <p>Project</p>
      <h4>
        <router-link :to="{ name: 'CaseDetails', params: { id: card.id } }">{{
          card.name
        }}</router-link>
      </h4>
    </div>
  </div>
</template>
<script>
export default {
  name: "CaseCard2",
  props: ["card", "type", "index"],
};
</script>
