<template>
  <div className="col-xl-12">
    <div
      v-for="(service, idx) in services"
      :key="service.id"
      class="single-service-item"
      :class="{ 'mt-30': idx === 0 }"
    >
      <ServiceLine :service="service" :idx="idx" :service-two="true" />
    </div>
  </div>
</template>
<script>
import axios from "axios";
import ServiceLine from "./ServiceLine.vue";
export default {
  name: "Service1",
  components: { ServiceLine },
  data() {
    return {
      services: [],
    };
  },
  created() {
    this.getData();
  },
  methods: {
    async getData() {
      try {
        let fetchedData = await axios.get("/assets/data/services.json");
        this.services = fetchedData.data.services;
      } catch (error) {
        console.log(error);
      }
    },
  },
};
</script>
