<template>
  <div
    class="testimonial-section testimonial-two green-bg section-padding pb-30"
  >
    <div class="row">
      <div class="offset-xl-2 col-xl-8 text-center">
        <div class="section-title">
          <p>Testimonials</p>
          <h2 class="text-white">
            A Satisfied Customer Is The Best Business Strategy Of All
          </h2>
        </div>
      </div>
    </div>
    <div class="container">
      <Swiper
        :modules="modules"
        :slides-per-view="1"
        :speed="1500"
        :space-between="30"
        :pagination="{ clickable: true }"
        :breakpoints="{
          '1100': {
            slidesPerView: 2,
          },
        }"
        class="testimonial-carousel"
        v-if="testimonials.length > 0"
      >
        <SwiperSlide
          v-for="slide in testimonials"
          :key="slide.id"
          class="single-testimonal-item"
        >
          <div class="testimonial-img">
            <img :src="slide.img" alt="" />
          </div>
          <div class="testimonial-content">
            <div class="quote-sign">
              <i class="las la-quote-left"></i>
            </div>
            <p class="text-black">
              {{ slide.comment }}
            </p>
            <h5 class="testimonial-title">
              {{ slide.name }} <span>{{ slide.rank }}</span>
            </h5>
            <div class="testimonal-review-wrap">
              <i class="las la-star"></i>
              <i class="las la-star"></i>
              <i class="las la-star"></i>
              <i class="las la-star"></i>
              <i class="las la-star"></i>
            </div>
          </div>
        </SwiperSlide>
      </Swiper>
    </div>
  </div>
</template>
<script>
import axios from "axios";
import { Swiper, SwiperSlide } from "swiper/vue";
import { Pagination } from "swiper";
export default {
  name: "Testimonial2",
  components: { Swiper, SwiperSlide },
  data() {
    return {
      testimonials: [],
      modules: [Pagination],
    };
  },
  created() {
    this.getData();
  },
  methods: {
    async getData() {
      try {
        let fetchedData = await axios.get("/assets/data/testimonials.json");
        this.testimonials = fetchedData.data.testimonials;
      } catch (error) {
        console.log(error);
      }
    },
  },
};
</script>
