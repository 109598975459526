<template>
  <div class="feature-section section-padding pb-0 white-bg">
    <div class="container">
      <div class="row">
        <div class="col-xl-12 text-center">
          <div class="section-title">
            <h2>
              We Have Than More
              <router-link :to="{ name: 'About' }"
                >25 Years of Experience</router-link
              >
              <br />
              in Tax Advisor & Financial Consulting <br />
              Services
            </h2>
          </div>
        </div>
      </div>
      <div class="row mt-60">
        <div
          class="col-xl-4 col-lg-4 col-md-6 col-12 wow fadeInUp animated"
          v-for="(feature, i) in features"
          :key="feature.id"
          :data-wow-delay="i * 100 + 'ms'"
        >
          <div class="feature-item-wrap">
            <div class="feature-icon">
              <i :class="feature.icon"></i>
            </div>
            <div class="feature-content">
              <h4>{{ feature.name }}</h4>
              <p>{{ feature.description }}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
export default {
  name: "Feature",
  data() {
    return {
      features: [],
    };
  },
  async beforeMount() {
    try {
      let fetchedData = await axios.get("/assets/data/features.json");
      this.features = fetchedData.data.features;
    } catch (error) {
      console.log(error);
    }
  },
};
</script>
