<template>
  <Navbar1 />

  <!-- Breadcrumb Area  -->

  <div class="breadcrumb-area services section-padding light-bg-1">
    <div class="container">
      <div class="row justify-content-center">
        <div class="col-xl-10 col-lg-10 col-md-10 col-12 text-center">
          <div class="section-title">
            <p>Taxation</p>
            <h2>{{ currentBlog.title }}</h2>
          </div>
        </div>
      </div>
      <div class="row justify-content-center">
        <div class="col-xl-4 col-lg-6 col-md-8 col-12">
          <div class="blog-meta-wrap">
            <div class="blog-meta">
              <p><i class="las la-calendar-alt"></i>{{ currentBlog.date }}</p>
            </div>
            <div class="blog-meta">
              <p>
                <i class="las la-comments"></i>{{ comments.length }} Comments
              </p>
            </div>
            <div class="blog-meta">
              <p><i class="las la-user-alt"></i>By {{ currentBlog.author }}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <!-- Blog Details Page  -->
  <div class="blog-details-page light-bg-1">
    <div class="container">
      <div class="row">
        <div class="col-xl-8 col-lg-8 col-12">
          <div class="blog-content-wrap">
            <div class="blog-feature-img">
              <img :src="currentBlog.banner_image_1" alt="" />
            </div>
            <p>
              {{ currentBlog.content_1 }} <br />
              <br />
              {{ currentBlog.content_2 }}
            </p>
            <div class="blog-quote-wrap">
              <div class="quote-icon">
                <i class="las la-quote-left"></i>
              </div>
              <div class="blog-quote-text">
                <p>{{ currentBlog.quote_text }}</p>
                <h6 class="blog-author">
                  {{ currentBlog.quote_author }} /
                  <span>{{ currentBlog.quote_author_designation }}</span>
                </h6>
              </div>
            </div>
            <h2>{{ currentBlog.sub_heading }}</h2>
            <p>
              {{ currentBlog.sub_heading_content_1 }} <br /><br />

              {{ currentBlog.sub_heading_content_2 }}
            </p>
            <div class="blog-feature-img">
              <img :src="currentBlog.banner_image_2" alt="" />
            </div>
            <p>{{ currentBlog.content_1 }}</p>
            <div class="row border-top border-bottom pt-30 pb-30 mt-30 mb-30">
              <div class="col-xl-6 col-lg-6 col-md-6">
                <div class="blog-tag-wrap d-flex align-items-center">
                  <h5>Tags:</h5>
                  <span>Advisor</span>
                  <span>Financials</span>
                </div>
              </div>
              <div class="col-xl-6 col-lg-6 col-md-6 text-end">
                <div class="social-icon">
                  <a href="#"><i class="lab la-facebook-f"></i></a>
                  <a href="#"><i class="lab la-twitter"></i></a>
                  <a href="#"><i class="lab la-instagram"></i></a>
                  <a href="#"><i class="lab la-dribbble"></i></a>
                </div>
              </div>
            </div>
            <div class="pagination-wrap">
              <a href="#"
                ><i class="las la-long-arrow-alt-left"></i>Previous Post</a
              >
              <a href="#"
                >Next Post<i class="las la-long-arrow-alt-right"></i
              ></a>
            </div>
          </div>
          <div class="commetns-section-wrap">
            <div class="comments-headline">
              <h3>Comments ({{ comments.length }})</h3>
            </div>
            <div
              class="single-comments-wrap"
              v-for="comment in comments"
              :key="comment.id"
            >
              <div class="single-comments-inner">
                <div class="comments-autor-thumb">
                  <img :src="comment.author_image" alt="" />
                </div>
                <div class="comments-content">
                  <h6>{{ comment.author_name }}</h6>
                  <p class="comments-date">
                    {{ comment.date }} - {{ comment.time }}
                  </p>
                  <p>{{ comment.comment }}</p>
                </div>
              </div>
              <a href="#" class="reply-btn">Reply</a>
            </div>
          </div>
          <div class="comments-form-wrap">
            <h3>Leave A Comment</h3>
            <div class="comments-form">
              <form action="#">
                <div class="row">
                  <div class="col-md-6 col-12">
                    <input type="text" placeholder="Name" />
                  </div>
                  <div class="col-md-6 col-12">
                    <input type="email" placeholder="Email" />
                  </div>
                  <div class="col-12">
                    <input type="tel" placeholder="Phone" />
                  </div>
                  <div class="col-12">
                    <textarea
                      name="message"
                      id="message"
                      cols="30"
                      rows="10"
                      placeholder="Write a Comment"
                    ></textarea>
                  </div>
                  <div class="col-12">
                    <input type="submit" value="Post Comment" />
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
        <div class="col-xl-4 col-lg-4 col-12">
          <div class="blog-sidebar">
            <div class="search-bar-wrap d-flex">
              <input type="search" placeholder="search" />
              <i class="fal fa-search"></i>
            </div>
            <div class="blog-category">
              <h5>Categories</h5>
              <ul>
                <li>
                  <i class="las la-angle-double-right"></i
                  ><router-link :to="{ name: 'Blogs' }"
                    >Investment
                  </router-link>
                </li>
                <li>
                  <i class="las la-angle-double-right"></i
                  ><router-link :to="{ name: 'Blogs' }">Taxation</router-link>
                </li>
                <li>
                  <i class="las la-angle-double-right"></i
                  ><router-link :to="{ name: 'Blogs' }">Insight</router-link>
                </li>
                <li>
                  <i class="las la-angle-double-right"></i
                  ><router-link :to="{ name: 'Blogs' }">Financial</router-link>
                </li>
                <li>
                  <i class="las la-angle-double-right"></i
                  ><router-link :to="{ name: 'Blogs' }">Strategy</router-link>
                </li>
              </ul>
            </div>
            <div class="recent-post-wrap">
              <h5>Recent Post</h5>
              <div
                class="single-recent-post"
                v-for="blog in blogs.slice(0, 3)"
                :key="blog.id"
              >
                <div class="recent-post-thumbs">
                  <img :src="blog.image" alt="" />
                </div>
                <div class="recent-post-content">
                  <p>{{ blog.date }}</p>
                  <router-link
                    :to="{ name: 'BlogDetails', params: { id: blog.id } }"
                    ><h6>{{ blog.title }}</h6></router-link
                  >
                </div>
              </div>
            </div>
            <div class="popular-tag-wrap">
              <h5>Popular Tag</h5>
              <span>Advisor</span>
              <span>Financials</span>
              <span>Tax</span>
              <span>Taxation</span>
              <span>Accounting</span>
            </div>
            <div class="question-wrap">
              <h5>Have Any Question?</h5>
              <p>
                There are many variations of passages of Lorem Ipsum available,
                but the alteration in some form,
              </p>
              <div class="contact-info">
                <div class="contact-icon">
                  <i class="las la-phone"></i>
                </div>
                <h4>(+62)81 122 4341</h4>
              </div>
              <div class="contact-info">
                <div class="contact-icon">
                  <i class="las la-envelope"></i>
                </div>
                <h4>info@email.com</h4>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <ContactComponent :className="true" />
  <Footer1 />
</template>

<script>
import axios from "axios";
import Navbar1 from "@/components/Navbar1.vue";
import Footer1 from "@/components/Footer1.vue";
import Collaboration from "@/components/Collaboration.vue";
import ContactComponent from "@/components/ContactComponent.vue";
export default {
  name: "BlogDetails",
  components: { Navbar1, Footer1, Collaboration, ContactComponent },
  data() {
    return {
      blogs: [],
      currentBlog: {},
      comments: [],
    };
  },
  async beforeMount() {
    let fetchedData = await axios.get("/assets/data/blogs.json");
    this.blogs = fetchedData.data.blogs;

    this.currentBlog = this.blogs.find(
      (item) => item.id == this.$route.params.id
    );

    this.comments = this.currentBlog.comments;
  },
  mounted() {
    // data-backround

    $("[data-background").each(function () {
      $(this).css(
        "background-image",
        "url( " + $(this).attr("data-background") + "  )"
      );
    });

    //jQuery Animation
    new WOW().init();
  },
};
</script>
