<template>
  <div class="service-section white-bg section-padding">
    <div class="container">
      <div class="row">
        <div
          class="col-xl-6 col-lg-6 col-12 wow fadeInLeft animated"
          data-wow-delay="200ms"
        >
          <div class="single-service-wrap" v-if="services.length > 0">
            <ServiceLine
              v-for="(service, idx) in services.slice(0, 4)"
              :service="service"
              :idx="idx"
              :key="service.id"
            />
          </div>
        </div>
        <div
          class="col-xl-6 col-lg-6 col-12 wow fadeInUp animated"
          data-wow-delay="200ms"
        >
          <div class="service-content-wrap">
            <div class="section-title">
              <h6>WHAT WE PROVIDE</h6>
              <h2>Loose And Experienced Tax Preparation</h2>
            </div>
            <p>
              There are many variations of passages of Lorem Ipsum available,
              but the majority suffered alteration in some form, by injected
              humour, or randomised words which don't look even slightly
              believable.
            </p>
            <div class="service-bg">
              <img src="/assets/img/service/service-bg.png" alt="" />
              <router-link :to="{ name: 'Services' }" class="more-service-btn"
                >Explore More Services</router-link
              >
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import axios from "axios";
import ServiceLine from "./ServiceLine.vue";
export default {
  name: "Service1",
  components: { ServiceLine },
  data() {
    return {
      services: [],
    };
  },
  created() {
    this.getData();
  },
  methods: {
    async getData() {
      try {
        let fetchedData = await axios.get("/assets/data/services.json");
        this.services = fetchedData.data.services;
      } catch (error) {
        console.log(error);
      }
    },
  },
};
</script>
