<template>
  <Navbar1 />
  <!-- Breadcrumb Area  -->
  <div class="breadcrumb-area section-padding light-bg-1 pb-0">
    <div class="container">
      <div class="row">
        <div class="col-xl-9 col-lg-9 col-md-9 col-12">
          <div class="section-title">
            <p>More About Us</p>
            <h2>We Provide The Best Service For Your Convenience</h2>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-xl-6 col-lg-6 col-md-6 col-12">
          <div class="direction-img">
            <img src="/assets/img/shape/shape-about.png" alt="" />
          </div>
        </div>
        <div class="col-xl-6 col-lg-6 col-md-6 col-12">
          <h3>Our History & Goal.</h3>
          <p>
            Many desktop publishing packages and web page editors now use Lorem
            Ipsum as their default model text, uncover many web sites still in
            infancy. various versions have evolved over the years, sometimes by
            sometimes on purpose injected humour and the like
          </p>
        </div>
      </div>
      <div class="row mt-90">
        <div class="col-12">
          <div class="bread-bg">
            <img src="/assets/img/breadcrumb/about_banner.jpg" alt="" />
          </div>
        </div>
      </div>
    </div>
  </div>

  <Feature />
  <AboutCompany :className="false" />
  <CTA />
  <TeamInHome :className="true" />
  <CTA2 />
  <Experience :className="false" />
  <Testimonial2 />
  <Collaboration :background_shape="true" />
  <Clients :type="false" />
  <ContactComponent />
  <Footer1 />
</template>
<script>
import Navbar1 from "@/components/Navbar1.vue";
import Footer1 from "@/components/Footer1.vue";
import CTA from "@/components/CTA.vue";
import ContactComponent from "@/components/ContactComponent.vue";
import Feature from "@/components/feature/Feature.vue";
import Collaboration from "@/components/Collaboration.vue";
import Clients from "@/components/Clients.vue";
import CTA2 from "@/components/cta/CTA2.vue";
import TeamInHome from "@/components/team/TeamInHome.vue";
import AboutCompany from "@/components/about/AboutCompany.vue";
import Experience from "@/components/Experience.vue";
import Testimonial2 from "@/components/testimonial/Testimonial2.vue";
export default {
  name: "AboutUs",
  components: {
    CTA,
    ContactComponent,
    Collaboration,
    Feature,
    Navbar1,
    Footer1,
    Clients,
    CTA2,
    TeamInHome,
    AboutCompany,
    Experience,
    Testimonial2,
  },
  mounted() {
    // data-backround

    $("[data-background").each(function () {
      $(this).css(
        "background-image",
        "url( " + $(this).attr("data-background") + "  )"
      );
    });

    //jQuery Animation
    new WOW().init();

    //Magnific Pop-up

    $(".video-play-btn").magnificPopup({
      type: "iframe",
    });
  },
};
</script>
