<template>
  <Navbar1 />
  <!-- Breadcrumb Area  -->
  <div class="breadcrumb-area services section-padding light-bg-1">
    <div class="container">
      <div class="row justify-content-center">
        <div class="col-xl-7 col-lg-7 col-md-8 col-12 text-center">
          <div class="section-title">
            <p>Blog & News</p>
            <h2>
              Check our inside news <br />
              & update
            </h2>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- Blog Page  -->
  <div class="blog-page pb-120 white-bg">
    <div class="container">
      <div class="row">
        <div class="col-xl-12">
          <div class="blog-slider-item">
            <img src="/assets/img/blog/blog_banner.jpg" alt="" />
            <div class="blog-content">
              <div class="single-blog-item">
                <div class="blog-meta">
                  <span>Taxation</span> . <span>25 March 2023</span>
                </div>
                <h3>
                  <router-link :to="{ name: 'BlogDetails', params: { id: 1 } }"
                    >If The White Whale Be Raised It Must Be In A
                    Month</router-link
                  >
                </h3>
                <p>
                  Duis aute irure dolor in reprehenderit in voluptate velit esse
                  dolore eu fugiat nulla pariatur. Excepteur sint occaecat
                  cupidatat
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="row justify-content-center mt-120">
        <div class="col-xl-6">
          <div class="blog-search-wrap">
            <h2>Check our inside News</h2>
            <div class="row justify-content-center">
              <div class="col-xl-6">
                <div class="search-bar mt-40">
                  <input type="text" placeholder="Search in Blog" />
                  <div class="search-icon">
                    <i class="fal fa-search"></i>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="row mt-60">
        <div
          class="col-md-6 col-12 text-center wow fadeInLeft animated"
          v-for="(blog, i) in blogs"
          :key="blog.id"
          :data-wow-delay="(i + 1) * 200 + 'ms'"
        >
          <div class="single-blog-item">
            <div class="blog-meta">
              <span>Taxation</span> . <span>{{ blog.date }}</span>
            </div>
            <h3>
              <router-link
                :to="{ name: 'BlogDetails', params: { id: blog.id } }"
                >{{ blog.title }}</router-link
              >
            </h3>
            <p>{{ blog.excerpt }}</p>
            <div class="blog-thumb">
              <img :src="blog.image" alt="" />
            </div>
          </div>
        </div>

        <ul class="pagination">
          <li class="page-item active"><a class="page-link" href="#">1</a></li>
          <li class="page-item" aria-current="page">
            <a class="page-link" href="#">2</a>
          </li>
          <li class="page-item"><a class="page-link" href="#">3</a></li>
          <li class="page-item">
            <a class="page-link" href="#"><i class="las la-angle-right"></i></a>
          </li>
        </ul>
      </div>
    </div>
  </div>

  <OurWorks />
  <Collaboration :background_shape="false" />
  <ContactComponent />
  <Footer1 />
</template>

<script>
import axios from "axios";
import Navbar1 from "@/components/Navbar1.vue";
import Footer1 from "@/components/Footer1.vue";
import Collaboration from "@/components/Collaboration.vue";
import ContactComponent from "@/components/ContactComponent.vue";
import OurWorks from "@/components/OurWorks.vue";
export default {
  name: "Blogs",
  components: { Navbar1, Footer1, Collaboration, ContactComponent, OurWorks },
  data() {
    return {
      blogs: [],
    };
  },
  async beforeMount() {
    let fetchedData = await axios.get("/assets/data/blogs.json");
    this.blogs = fetchedData.data.blogs;
  },
  mounted() {
    // data-backround
    $("[data-background").each(function () {
      $(this).css(
        "background-image",
        "url( " + $(this).attr("data-background") + "  )"
      );
    });

    //jQuery Animation
    new WOW().init();

    //Magnific Pop-up

    $(".video-play-btn").magnificPopup({
      type: "iframe",
    });
  },
};
</script>
