<template>
  <div class="single-service-inner">
    <h5>
      <router-link :to="{ name: 'ServiceDetails', params: { id: service.id } }"
        >{{ serviceTwo ? `0${idx + 1}/` : null }}
        {{ service.name }}</router-link
      >
    </h5>
    <p>
      {{ service.description.d1 }} <br />
      {{ service.description.d2 }}
    </p>
    <router-link
      :to="{ name: 'ServiceDetails', params: { id: service.id } }"
      class="details-link"
      ><i class="las la-arrow-right"></i
    ></router-link>
  </div>
  <div v-if="serviceTwo" class="service-img">
    <img :src="service.image" alt="" />
  </div>
</template>

<script>
export default {
  name: "ServiceLine",
  props: ["serviceTwo", "service", "idx"],
};
</script>
