<template>
  <div class="testimonial-section section-padding">
    <div class="container">
      <div class="row justify-content-center">
        <div class="col-xl-8 text-center">
          <div class="section-title">
            <h6>Our Testimonials</h6>
            <h2>A Satisfied Customer Is The Best Business Strategy Of All</h2>
          </div>
        </div>
      </div>
      <div class="row justify-content-center">
        <div class="col-xl-12">
          <div class="testimonial-wrapper-one">
            <Carousel
              :wrap-around="true"
              v-model="currentSlide"
              id="thumbnails"
              class="testimonial-author-wrap"
              :breakpoints="{
                0: {
                  itemsToShow: 3,
                  snapAlign: 'center',
                },
                1000: {
                  itemsToShow: 4,
                  snapAlign: 'start',
                },
              }"
            >
              <Slide
                v-for="(slide, idx) in testimonials"
                :key="slide.id"
                @click="slideTo(idx)"
              >
                <div
                  class="single-testimonial-author d-flex align-items-center"
                >
                  <div class="testimonial-thumb">
                    <img :src="slide.thumb" :alt="slide.name" />
                  </div>
                  <div class="testimonial-content">
                    <h6>{{ slide.name }}</h6>
                    <p>{{ slide.rank }}</p>
                  </div>
                </div>
              </Slide>
            </Carousel>
            <Carousel
              id="gallery"
              :items-to-show="1"
              :wrap-around="true"
              v-model="currentSlide"
              ref="carousel"
              :breakpoints="{
                0: {
                  snapAlign: 'center',
                },
                1200: {
                  snapAlign: 'center',
                },
              }"
              class="testimonial-slider-wrap"
            >
              <Slide
                v-for="(slide, idx) in testimonials"
                :key="slide.id"
                @click="slideTo(idx)"
              >
                <div class="testimonial-slide-inner">
                  <div class="testimonal-thumb">
                    <img :src="slide.img" alt="client-1" />
                  </div>
                  <div class="testimonal-slider-content">
                    <h6>{{ slide.name }}</h6>
                    <p class="designation">{{ slide.rank }}</p>
                    <p>
                      {{ slide.comment }}
                    </p>
                    <div class="testimonal-review-wrap">
                      <i class="las la-star"></i>
                      <i class="las la-star"></i>
                      <i class="las la-star"></i>
                      <i class="las la-star"></i>
                      <i class="las la-star"></i>
                      <span>{{ slide.rating }}</span>
                    </div>
                  </div>
                </div>
              </Slide>
              <template #addons>
                <Pagination />
              </template>
            </Carousel>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import axios from "axios";
import { Carousel, Slide, Pagination } from "vue3-carousel";

import "vue3-carousel/dist/carousel.css";
export default {
  name: "Testnimonial1",
  components: { Carousel, Slide, Pagination },
  data() {
    return {
      testimonials: [],
      currentSlide: 0,
    };
  },
  created() {
    this.getData();
  },
  methods: {
    async getData() {
      try {
        let fetchedData = await axios.get("/assets/data/testimonials.json");
        this.testimonials = fetchedData.data.testimonials;
      } catch (error) {
        console.log(error);
      }
    },
    slideTo(val) {
      this.currentSlide = val;
    },
  },
};
</script>
