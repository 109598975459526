<template>
  <div class="accordion faqs" id="accordionFaq" v-if="faq.length > 0">
    <div
      v-for="(fq, idx) in type ? faq.slice(0, 3) : faq"
      :key="fq.id"
      class="card"
    >
      <div class="card-header" :id="`heading${fq.id}`">
        <h5 class="mb-0 subtitle">
          <button
            :class="`btn btn-link active ${
              fq.id === 1 && firstTime ? '' : 'collapsed'
            }`"
            type="button"
            data-bs-toggle="collapse"
            :data-bs-target="`#collapse${fq.id}`"
            aria-expanded="false"
            :aria-controls="`collapse${fq.id}`"
          >
            {{ fq.question }}
          </button>
        </h5>
      </div>

      <div
        @click.prevent="setFirstTime(false)"
        :id="`collapse${fq.id}`"
        :class="`collapse ${fq.id === 1 && firstTime ? 'show' : ''}`"
        :aria-labelledby="`heading${fq.id}`"
        data-parent="#accordionFaq"
      >
        <div class="card-body">
          <div class="content">
            <p>
              {{ fq.answer }}
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import axios from "axios";
export default {
  name: "Accordion",
  props: ["type"],

  data() {
    return {
      faq: [],
      firstTime: true,
    };
  },
  created() {
    this.getData();
  },
  methods: {
    async getData() {
      try {
        let fetchedData = await axios.get("/assets/data/faq.json");
        this.faq = fetchedData.data.faq;
      } catch (error) {
        console.log(error);
      }
    },
    setFirstTime(value) {
      this.firstTime = value;
    },
  },
};
</script>
