<template>
  <div
    class="clients-area white-bg"
    :class="type ? 'pb-120' : 'section-padding'"
  >
    <div class="client-logo-wrap">
      <img src="/assets/img/client/themeforest.png" alt="themeforest-logo" />
      <img src="/assets/img/client/codecanyon.png" alt="codecanyon-logo" />
      <img src="/assets/img/client/videohive.png" alt="videohibe-logo" />
      <img src="/assets/img/client/graphicriver.png" alt="graphicriver-logo" />
    </div>
  </div>
</template>
<script>
export default {
  name: "Clients",
  props: ["type"],
};
</script>
