<template lang="">
  <div class="cta-area cta-two" data-background="/assets/img/cta_bg_2.jpg">
    <div class="cta-inner-text">
      <div class="text-left">
        <h2 class="text-white">1K+ Satisfied Clients</h2>
      </div>
      <div class="text-right">
        <h2 class="text-white">120+EXPERT TEAM</h2>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "OurWorks",
};
</script>
