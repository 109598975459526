<template lang="">
  <div class="choose-us-section section-padding light-bg-1">
    <div class="container">
      <div class="row">
        <div class="offset-xl-1 col-xl-10 text-center">
          <div class="section-title">
            <p>WHY CHOOSE US</p>
            <h2>We Provide High-Quality Accounting & Tax Service</h2>
          </div>
        </div>
      </div>
      <div class="row mt-60">
        <div
          class="col-xl-3 col-lg-3 col-md-6 col-12"
          v-for="(choose, i) in choose_us"
          :key="choose.id"
        >
          <div
            class="single-feature-item"
            :class="(i + 1) % 2 != 0 ? 'mt-60' : ''"
          >
            <div class="feature-icon">
              <i :class="choose.icon"></i>
            </div>
            <div class="feature-title">
              <h4>{{ choose.name }}</h4>
            </div>
            <p class="text-black">{{ choose.description }}</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import axios from "axios";

export default {
  name: "ChooseUs",
  data() {
    return {
      choose_us: [],
    };
  },
  async beforeMount() {
    let fetchedChooseData = await axios.get("/assets/data/choose_us.json");
    this.choose_us = fetchedChooseData.data.choose_us;
  },
};
</script>
